import React from "react";
import { connect } from "react-redux";
import {
  GET_BASKET,
  SET_BASKET_DETAILS,
  SELECT_BASKET_ITEM,
} from "../../../constants/actionTypes";
import MerModal from "../../../components/common/MerModal";
import BasketEditModal from "./components/BasketEditModal";
import NumberFormatter from "../../../components/common/NumberFormatter";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import BlankPage from "../../../components/BlankPage";
import Loader from "../../../components/Loader";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return { Basket: state.basket };
};

const mapDispatchToProps = (dispatch) => ({
  basketOnLoad: (payload) => dispatch({ type: GET_BASKET, payload }),
  onChangeDemandQuantity: (value) =>
    dispatch({ type: SET_BASKET_DETAILS, value, key: "DemandQuantity" }),
  onChangeDemandPrice: (value) =>
    dispatch({ type: SET_BASKET_DETAILS, value, key: "DemandPrice" }),
  onSelectBasketItem: (value) => dispatch({ type: SELECT_BASKET_ITEM, value }),
});

class Basket extends React.Component {
  constructor() {
    super();
    this.state = {
      isButtonDisabled: false,
      showModal: false,
    };

    this.changeDemandQuantity = (ev) => this.props.onChangeDemandQuantity(ev);
    this.changeDemandPrice = (ev) => this.props.onChangeDemandPrice(ev);

    this.deleteBasketItem = async (ProductId) => {
      await services.product.deleteBasketItem(ProductId);
      await this.getBasket();
    };

    this.openModal = (item) => {
      this.props.onChangeDemandQuantity(item.Quantity);
      this.props.onChangeDemandPrice(item.DemandPrice);
      this.props.onSelectBasketItem(item);
      this.setState({ showModal: true });
    };

    this.saveBasket = async (Units, DemandPrice, ProductId, Currency) => {
      services.product.saveBasket(Units, DemandPrice, ProductId, Currency);
    };

    this.submitBasket = (ev) => {
      this.setState({ isButtonDisabled: true });
      services.product
        .submitBasket()
        .then((res) => {
          toast.success(<div>Your order created</div>);
          this.getBasket();
          this.setState({ isButtonDisabled: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        });
    };
    this.saveBasketItem = async (Quantity, DemandPrice, id) => {
      services.product
        .saveBasketItem(Quantity, DemandPrice, id)
        .then((res) => {
          this.getBasket();
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        });
    };
  }

  getBasket() {
    this.props.basketOnLoad(Promise.all([services.product.getBasket()]));
  }
  render() {
    const { Quantity, DemandPrice, basket, selectedItem } = this.props.Basket;
    const basketItems = basket ? basket[0] : undefined;
    let total = 0;
    let totalAmount = 0;
    if (basketItems === undefined) {
      return <Loader></Loader>;
    } else if (basketItems.Items.length === 0) {
      return (
        <Content>
          <h1>My Cart</h1>
          <div className="">
            <BlankPage description="List is empty" />
          </div>
        </Content>
      );
    } else {
      return (
        <Content>
          <h1>
            My Cart
            <span className="items-count-text ms-2 h3">
              <i /> ({basketItems?.Items.length} Item
              {basketItems?.Items.length > 1 ? "s" : ""})
            </span>
          </h1>
          <div className="row mt-4">
            <div className="col-lg-7">
              <ul className="p-0 me-5">
                {basketItems?.Items?.map((item, key) => {
                  total = item.DemandPrice * item.Quantity;
                  totalAmount += item.DemandPrice * item.Quantity;
                  return (
                    <div className="card py-5 px-5 pb-3" key={key}>
                      <div className="d-flex align-items-start flex-row justify-content-between">
                        <li className=" d-flex align-items-center">
                          <div>
                            <h3 className="basket-card-text h5 mb-1">
                              {item.Name}
                            </h3>

                            <p className="small mb-1 items-count-text">
                              Publisher: {item.Publisher}
                            </p>
                          </div>
                        </li>
                        <li className=" d-flex align-items-center">
                          <div className="btn-group" role="group">
                            <a onClick={() => this.openModal(item)}>
                              <span className="px-1 basket-card-text">
                                Edit
                              </span>
                            </a>
                          </div>
                        </li>
                      </div>

                      <div className="d-flex align-items-start flex-row justify-content-between">
                        <p className="view-price-quantity ">
                          {item.Quantity} {" x "}
                          <NumberFormatter
                            offerPrice={item.DemandPrice}
                            supVisible
                          />
                        </p>

                        <a
                          className="remove-basket"
                          type="button"
                          onClick={() => this.deleteBasketItem(item.ProductId)}
                        >
                          <i className="fas fa-trash-alt m-2" />
                        </a>
                      </div>
                      {item.ValidFrom === null && item.ValidTo === null && (
                        <p
                          className="pt-3"
                          style={{ fontSize: "11px", color: "#fa758a" }}
                        >
                          * This promotion has expired. Please remove it from
                          your list to be able to continue with your process.
                        </p>
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
            <div className="col-lg-5">
              <div className="card py-5 px-4">
                <div className=" p-3">
                  <h1 className="basket-summary-text">Summary</h1>
                </div>
                <div className="card-body px-0 py-0">
                  <ul className="list-group">
                    {basket[0]?.Items?.map((item, key) => {
                      return (
                        <li
                          className="list-group-item border-bottom py-3"
                          key={key}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <h3 className="basket-card-text h5 mb-1">
                                {item.Name}
                              </h3>
                              <h6 className="basket-card-text d-flex justify-content-end m-2">
                                <span>{item.Quantity + " x"}</span>
                                <span className="mx-1">
                                  <NumberFormatter
                                    offerPrice={item.DemandPrice}
                                    supVisible
                                  />
                                </span>
                              </h6>
                              <h6 className="basket-card-text d-flex justify-content-end m-2">
                                <span className="basket-card-text mr-3">
                                  {"Total Price : "}
                                  <NumberFormatter
                                    offerPrice={item.DemandPrice}
                                    offerQuantity={item.Quantity}
                                    supVisible
                                  />
                                </span>
                              </h6>
                            </div>
                          </div>
                        </li>
                      );
                    })}

                    <li className="list-group-item py-3">
                      <div className="row align-items-center">
                        <div className="col m-3">
                          <h3 className="basket-card-text h5 mb-3 d-flex justify-content-end">
                            Total
                          </h3>
                          <h1 className="d-flex justify-content-end">
                            <NumberFormatter
                              offerPrice={totalAmount}
                              supVisible
                            />
                          </h1>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <button
                  className="submit-btn"
                  onClick={() => this.submitBasket()}
                  disabled={
                    this.state.isButtonDisabled ||
                    basketItems?.Items.filter(
                      (f) => f.ValidFrom === null && f.ValidTo === null
                    ).length > 0
                  }
                >
                  <i className="fas fa-check m-2" />
                  Submit Orders
                </button>
              </div>
            </div>
          </div>
          <MerModal
            showModal={this.state.showModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => this.setState({ showModal: false })}
          >
            <BasketEditModal
              selectedItem={this.props.item?.Id}
              changeDemandPrice={(_value) => this.changeDemandPrice(_value)}
              changeDemandQuantity={(_value) =>
                this.changeDemandQuantity(_value)
              }
              saveBasketItem={(_demandQuantity, _demandPrice, _promotionId) =>
                this.saveBasketItem(_demandQuantity, _demandPrice, _promotionId)
              }
              handleClose={() => this.setState({ showModal: false })}
            ></BasketEditModal>
          </MerModal>
        </Content>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
