import React, { useEffect, useState } from "react";
import { store } from "../store";

// eslint-disable-next-line import/prefer-default-export
export const StaticPermission = {
  Invoice: {
    Create: "Invoice.Create",
    Download: "Invoice.Download",
    View: "Invoice.View",
  },
  Order: {
    Approve: "Order.Approve",
    Cancel: "Order.Cancel",
    Close: "Order.Close",
    Confirm: "Order.Confirm",
    Create: "Order.Create",
    CreateFromInventory: "Order.CreateFromInventory",
    CreatePackage: "Order.CreatePackage",
    Delete: "Order.Delete",
    Edit: "Order.Edit",
    ReBid: "Order.ReBid",
    Refund: "Order.Refund",
    RefundPackage: "Order.RefundPackage",
    RefundRequest: "Order.RefundRequest",
    Refuse: "Order.Refuse",
    Reject: "Order.Reject",
    Reprocess: "Order.Reprocess",
    Revoke: "Order.Revoke",
    SendPackage: "Order.SendPackage",
    SendToInventory: "Order.SendToInventory",
    View: "Order.View",
    Withdraw: "Order.Withdraw",
  },
  Products: { View: "Products.View" },
  Promotion: {
    Create: "Promotion.Create",
    Delete: "Promotion.Delete",
    DirectSalesView: "Promotion.DirectSalesView",
    Edit: "Promotion.Edit",
    UpcomingView: "Promotion.UpcomingView",
    View: "Promotion.View",
  },
};

function CheckPermission(checkPermission, mypermission = null) {
  let result;
  if (mypermission) {
    result = mypermission[checkPermission.split(".")[0]]?.find(
      (f) => f === checkPermission.split(".")[1]
    );
  } else {
    let state = store.getState().permission;
    if (checkPermission && state.mypermission !== undefined) {
      result = state.mypermission[checkPermission.split(".")[0]]?.find(
        (f) => f === checkPermission.split(".")[1]
      );
    } else return false;
  }
  return result !== undefined ? true : false;
}

export default CheckPermission;
