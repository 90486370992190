import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { superadmin, TabName } from "../constants/defaults";

function OrderTabs(props) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        {window.location.pathname?.includes("management") ? (
          <div>
            <Tabs
              id="management-orders-tab-menu"
              activeKey={props.activeKey}
              onSelect={(k) => {
                props.setTabKey(k);
              }}
            >
              <Tab
                eventKey={TabName.Management.sendToConfirmation}
                title={`Sent to Confirmation (${
                  props.tabStatusCount?.sendToConfirmation ?? 0
                })`}
              ></Tab>
              {props.userRole?.includes(superadmin) ? (
                <Tab
                  eventKey={TabName.Management.salesmanApproved}
                  title={`Salesman Approved(${
                    props.tabStatusCount?.salesmanApproved ?? 0
                  })`}
                ></Tab>
              ) : null}
              <Tab
                eventKey={TabName.Management.waitingMyApproval}
                title={`Waiting Your Approval (${
                  props.tabStatusCount?.waitingMyApproval ?? 0
                })`}
              ></Tab>
              <Tab
                eventKey={TabName.Management.allOrders}
                title={`All Orders (${props.tabStatusCount?.allOrders ?? 0})`}
              ></Tab>
            </Tabs>
          </div>
        ) : (
          <Tabs
            id="partner-orders-tab-menu"
            activeKey={props.activeKey}
            onSelect={(k) => {
              props.setTabKey(k);
            }}
          >
            <Tab
              eventKey={TabName.Partner.sendToApproval}
              title={`Sent to Approval (${
                props.tabStatusCount?.sendToApproval ?? 0
              })`}
            ></Tab>
            <Tab
              eventKey={TabName.Partner.waitingMyConfirmation}
              title={`Waiting Your Confirmation (${
                props.tabStatusCount?.waitingMyConfirmation ?? 0
              })`}
            ></Tab>
            <Tab
              eventKey={TabName.Partner.allOrders}
              title={`All Orders (${props.tabStatusCount?.allOrders ?? 0})`}
            ></Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default OrderTabs;
