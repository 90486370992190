import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  REDIRECT,
  SELECT_PROMOTION,
  PROMOTIONS_LIST_PARTNER_PAGE_LOADED,
  CHANGE_PRICE_DETAILS_BASKET,
  ADD_TO_BASKET,
  GET_BASKET,
} from "../../../constants/actionTypes";
import Pager from "../../../components/Pager";
import { Endpoints } from "../../../constants/endpoints";
import Filter from "../../../components/Filter";
import Loader from "../../../components/Loader";
import { dateTypes, osTypes } from "../../../constants/defaults";
import Button from "../../../components/common/Button";
import MerModal from "../../../components/common/MerModal";
import PartnerAddViewModal from "./components/PartnerAddViewModal";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import BlankPage from "../../../components/BlankPage";
import { getSorting } from "../../../functions/FilterFunctions";
import base64 from "base-64";
import services from "../../../api/index";
import moment from "moment";
import RegionTable from "../../../components/RegionTable";

const mapStateToProps = (state) => {
  return {
    promotions_partner: state.promotions.promotions_partner,
    search: state.promotions.promotionSearchText,
    promotions: state.promotions,
    Basket: state.basket,
    redirectTo: state.common.redirectTo,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  promotionsPageOnLoad: (payload) =>
    dispatch({ type: PROMOTIONS_LIST_PARTNER_PAGE_LOADED, payload }),
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
  onSelectPromotion: (item) =>
    dispatch({ type: SELECT_PROMOTION, promotion: item }),
  onChangeDemandQuantity: (value) =>
    dispatch({
      type: CHANGE_PRICE_DETAILS_BASKET,
      key: "demandQuantity",
      value,
    }),
  onChangeDemandPrice: (value) =>
    dispatch({ type: CHANGE_PRICE_DETAILS_BASKET, key: "demandPrice", value }),
  onChangeCustomPrice: (value) =>
    dispatch({ type: CHANGE_PRICE_DETAILS_BASKET, key: "customPrice", value }),
  addToBasket: (payload) => dispatch({ type: ADD_TO_BASKET, payload }),
  basketOnLoad: (payload) => dispatch({ type: GET_BASKET, payload }),
});
function AllPromotions(props) {
  const [pageIndex, setPageIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [sortBy, setSortBy] = useState(props.match.params.sort);
  const [sortTypePrice, setSortTypePrice] = useState("down");
  const [sortTypeName, setSortTypeName] = useState("down");
  const [allPlatforms, setAllPlatforms] = useState([]);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  useEffect(() => {
    getAllPlatforms();
    getPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onFilter({ pageindex: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const changeDemandPrice = (ev) => {
    props.onChangeDemandPrice(ev);
  };
  const changeCustomPrice = (ev) =>
    props.onChangeCustomPrice(ev.target.checked);
  const changeDemandQuantity = (ev) => props.onChangeDemandQuantity(ev);

  const getPromotions = () => {
    let ev = myfilter;

    getPromotionList({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      validfrom: ev?.DateRange?.min,
      validto: ev?.DateRange?.max,
      OperatingSystems: ev?.OperatingSystems?.join(","),
      Platforms: ev?.Platforms?.join(","),
      pageindex: pageIndex,
      SortBy: sortBy,
    });
  };

  const getPromotionList = (params) => {
    setTableIsLoading(true);
    const {
      keywords = "",
      validfrom = "",
      validto = "",
      pageindex = pageIndex,
      pagesize = props.pageSize,
      name = "",
      sku = "",
      mSku = "",
      ReleaseDateFrom = "",
      ReleaseDateTo = "",
      PublisherIds = "",
      SortBy = "",
      MinPrice = undefined,
      MaxPrice = undefined,
      OperatingSystems = "",
      Platforms = "",
    } = params || {};
    setPageIndex(pageindex);
    props.promotionsPageOnLoad(
      Promise.all([
        services.campaigns.getCampaignsFromPartner(
          keywords,
          validfrom,
          validto,
          pageindex,
          pagesize,
          name,
          sku,
          mSku,
          ReleaseDateFrom,
          ReleaseDateTo,
          PublisherIds,
          MinPrice,
          MaxPrice,
          OperatingSystems,
          Platforms,
          SortBy
        ),
      ])
        .then((res) => {
          setTableIsLoading(false);
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const selectPromotion = (item) => {
    props.onChangeCustomPrice(false);
    props.onSelectPromotion(item);
    props.onChangeDemandQuantity(item?.MinQuantity);
    props.onChangeDemandPrice(item?.Price);
    handlePartnerModalShow();
  };

  const addToBasket = (demandQuantity, demandPrice, productid) => {
    props.addToBasket(
      Promise.all([
        services.product
          .addToBasket(demandQuantity, demandPrice, productid)
          .then((res) => {
            handlePartnerModalClose();
            props.basketOnLoad(
              Promise.all([services.product.getBasket()])
                .then((result) => {
                  return result;
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                  return undefined;
                })
            );
            toast.success(<div>The product added to your cart</div>);
            return res;
          })
          .catch((err) => {
            if (err.response?.data?.message)
              toast.error(<div>{err.response?.data.message}</div>);
            return undefined;
          }),
      ])
    );
  };

  const getAllPlatforms = () => {
    services.common
      .getAllPlatforms()
      .then((response) => {
        setAllPlatforms(response);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  const handlePartnerModalClose = () => {
    setShowPartnerModal(false);
  };

  const handlePartnerModalShow = () => {
    setShowPartnerModal(true);
  };

  const onFilter = (params) => {
    let { filter, sortby = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;
    sortby = getSorting(sortby, sortBy);
    setSortBy(sortby);

    let url = Endpoints.Partner.AllPromotions.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getPromotionList({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      validfrom: ev?.DateRange?.min,
      validto: ev?.DateRange?.max,
      OperatingSystems: ev?.OperatingSystems?.join(","),
      Platforms: ev?.Platforms?.join(","),
      pageindex: pageindex,
      SortBy: sortBy,
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const promotions_partner = props.promotions_partner
    ? props.promotions_partner[0]
    : undefined;

  return (
    <Content pageTitle="All Promotions">
      {promotions_partner === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev) => onFilter(ev)}
            isPublisherAvailable
            dateList={[
              dateTypes.ALLTIME,
              dateTypes.THISWEEK,
              dateTypes.THISMONTH,
              dateTypes.LAST2MONTH,
            ]}
            priceList={[
              { down: undefined, up: 25 },
              { down: 25, up: 50 },
              { down: 50, up: 100 },
              { down: 100, up: 200 },
              { down: 300, up: undefined },
            ]}
            platformList={allPlatforms.map((element) => element)}
            osList={[osTypes.WINDOWS, osTypes.LINUX, osTypes.MACOS]}
          ></Filter>
          {tableIsLoading ? (
            <Loader />
          ) : promotions_partner.Items.length === 0 ? (
            <BlankPage description="There is no promotion." />
          ) : (
            <div>
              <div className="table-responsive-xl">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th
                        className="bg-transparent border-0"
                        onClick={() => {
                          setSortTypeName(
                            sortTypeName === "up" ? "down" : "up"
                          );
                          onFilter({
                            sortby: "Name",
                          });
                        }}
                      >
                        <div>
                          Name
                          <i
                            className={`fas fa-sort-amount-${sortTypeName} px-2`}
                          ></i>
                        </div>
                      </th>
                      <th className="bg-transparent border-0 ">Publisher</th>
                      <th className="bg-transparent border-0 ">Region</th>
                      <th
                        className="bg-transparent border-0 "
                        onClick={() => {
                          setSortTypePrice(
                            sortTypePrice === "up" ? "down" : "up"
                          );
                          onFilter({
                            sortby: "Price",
                          });
                        }}
                      >
                        <div>
                          Price
                          <i
                            className={`fas fa-sort-amount-${sortTypePrice} px-2`}
                          ></i>
                        </div>
                      </th>

                      <th className="bg-transparent border-0 ">Starts in</th>
                      <th className="bg-transparent border-0 ">Ends in</th>
                      <th className="bg-transparent border-0 ">OS</th>
                      <th className="bg-transparent border-0 ">Platform</th>
                      <th className="bg-transparent border-0 ">
                        Geo Black/White List
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotions_partner?.Items.map((item, index) => {
                      return (
                        <tr
                          className="rounded-1 mb-1 align-middle border-bottom "
                          key={index}
                        >
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>{item.Product.Name}</p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>{item.Product.Publisher}</p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>{item.Product.Region}</p>
                          </td>

                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>{item.Price}€</p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>
                              {" "}
                              {moment
                                .utc(item.ValidFrom)
                                .format("DD/MM/YYYY - H:mm:ss")}{" "}
                            </p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>
                              {" "}
                              {moment
                                .utc(item.ValidTo)
                                .format("DD/MM/YYYY - H:mm:ss")}{" "}
                            </p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <>
                              {item.OperatingSystems.map((os, osindex) => (
                                <div key={osindex}>
                                  {os === osTypes.WINDOWS.text && (
                                    <i className="fab fa-windows fa-2x mx-1" />
                                  )}
                                  {(os === osTypes.MACOS.text ||
                                    item === "Mac") && (
                                    <i className="fab fa-apple fa-2x mx-1" />
                                  )}
                                  {os === osTypes.LINUX.text && (
                                    <i className="fab fa-linux fa-2x mx-1" />
                                  )}
                                </div>
                              ))}
                            </>
                          </td>

                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <p>{item.Platforms.join(",")}</p>
                          </td>
                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-0"
                            style={{ fontSize: "0.7rem" }}
                          >
                            <div className="countries">
                              <RegionTable
                                title="Geo White Country List"
                                regions={item.Product.GeoWhiteList ?? []}
                                size={2}
                              />
                            </div>
                            <div className="countries">
                              <RegionTable
                                title="Geo Black Country List"
                                regions={item.Product.GeoBlackList ?? []}
                                size={2}
                              />
                            </div>
                          </td>

                          <td
                            className="fmxw-350 bg-white border-0 text-gray-600 fw-bold p-3"
                            style={{ verticalAlign: "middle" }}
                          >
                            <div>
                              <Button
                                disabled={
                                  props.Basket.basket[0].Items.filter(
                                    (f) => f.ProductId === item.Product.Id
                                  ).length > 0
                                }
                                className="detail-button px-3"
                                label="Add to cart"
                                bindEvent={() => {
                                  selectPromotion(item);
                                  // : this.props.onRedirect(Endpoints.Basket.url)
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Pager
                pageIndex={promotions_partner.PageIndex}
                totalPages={promotions_partner.TotalPages}
                totalCount={promotions_partner?.TotalCount}
                onChange={(pageindex) => onChangePageIndex(pageindex)}
              />
            </div>
          )}
          <MerModal
            showModal={showPartnerModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => handlePartnerModalClose()}
          >
            <PartnerAddViewModal
              orderId={props.item?.Id}
              changeCustomPrice={(_value) => changeCustomPrice(_value)}
              changeDemandPrice={(_value) => changeDemandPrice(_value)}
              changeDemandQuantity={(_value) => changeDemandQuantity(_value)}
              addToBasket={(_demandQuantity, _demandPrice, _productId) =>
                addToBasket(_demandQuantity, _demandPrice, _productId)
              }
              handleClose={() => handlePartnerModalClose()}
            ></PartnerAddViewModal>
          </MerModal>
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPromotions);
