export default (req) => ({
  getAllProductFromManagement: (
    keywords,
    pageindex = 0,
    pagesize = 10,
    sku,
    OnlyActivePromotions = false,
    store
  ) =>
    req.get("/providerproduct/management/GetAll", {
      params: {
        Keywords: keywords,
        PageIndex: pageindex,
        PageSize: pagesize,
        Sku: sku,
        OnlyActivePromotions: OnlyActivePromotions,
        Store: store,
      },
    }),
  getProductFromManagement: (productid) =>
    req.get("/product/management/" + productid),
  savePrice: (price_id, product_id, price) =>
    req.put("/Product/management/" + product_id + "/ChangePrice", {
      id: price_id,
      price: price,
    }),
  addToBasket: (demandQuantity, demandPrice, productid) =>
    req.post("/basket/me", {
      ProductId: productid,
      Quantity: demandQuantity,
      DemandPrice: demandPrice,
    }),
  getBasket: () => req.get("/basket/me"),
  saveBasketItem: (Quantity, DemandPrice, ProductId) =>
    req.put("/basket/me", {
      ProductId: ProductId,
      Quantity: Quantity,
      DemandPrice: DemandPrice,
    }),
  deleteBasketItem: (productid) =>
    req.del("/basket/me", { data: { ProductId: productid } }),
  submitBasket: () => req.post("/basket/me/submit"),
  bulkAddToCart: (bulkPromotionItems) =>
    req.post("/basket/me/addbulk", bulkPromotionItems),
  getAverage: (countries, product_id) =>
    req.post(
      "/providerproduct/management/" + product_id + "/CalculateAvgPrice",
      {
        countries: countries,
      }
    ),
  getAllProducts: (
    keywords,
    name,
    sku,
    mSku,
    validTo,
    validFrom,
    releaseDateFrom,
    releaseDateTo,
    publisherIds,
    providerIds,
    store,
    onlyActivePromotions,
    sortBy,
    pageIndex = 0,
    pageSize = 10
  ) =>
    req.get("/providerproduct/management/Search", {
      params: {
        Keywords: keywords,
        Name: name,
        Sku: sku,
        MSku: mSku,
        ValidTo: validTo,
        ValidFrom: validFrom,
        ReleaseDateFrom: releaseDateFrom,
        ReleaseDateTo: releaseDateTo,
        PublisherIds: publisherIds,
        ProviderIds: providerIds,
        Store: store,
        OnlyActivePromotions: onlyActivePromotions,
        SortBy: sortBy,
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
});
