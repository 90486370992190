export default (req) => ({
  getCompanies: (keywords, pageIndex, pageSize) =>
    req.get(
      `/company/management/search?PageIndex=${pageIndex}&PageSize=${pageSize}&Keywords=${keywords}`
    ),
  createCompany: (
    name,
    email,
    type,
    address,
    zipCode,
    state,
    city,
    country,
    countryCode,
    accountNumber,
    vatRate,
    totalCreditLimit,
    balance,
    packageLimit
  ) =>
    req.post("/company/management/create", {
      CompanyName: name,
      CompanyEmail: email,
      CompanyType: type,
      Address: address,
      ZipCode: zipCode,
      State: state,
      City: city,
      Country: country,
      CountryCode: countryCode,
      AccountNumber: accountNumber,
      VatRate: vatRate,
      TotalCreditLimit: totalCreditLimit,
      Balance: balance,
      PackageLimit: packageLimit,
    }),
  updateCompany: (
    companyId,
    name,
    email,
    type,
    address,
    zipCode,
    state,
    city,
    country,
    countryCode,
    accountNumber,
    vatRate,
    totalCreditLimit,
    balance,
    packageLimit
  ) =>
    req.put(`/company/management/${companyId}/update`, {
      CompanyName: name,
      CompanyEmail: email,
      CompanyType: type,
      Address: address,
      ZipCode: zipCode,
      State: state,
      City: city,
      Country: country,
      CountryCode: countryCode,
      AccountNumber: accountNumber,
      VatRate: vatRate,
      TotalCreditLimit: totalCreditLimit,
      Balance: balance,
      PackageLimit: packageLimit,
    }),
});
