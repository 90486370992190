import React, { useState } from "react";
import NumberInput from "../../../../components/common/NumberInput";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";
import NumberFormatter from "../../../../components/common/NumberFormatter";
import { Icons } from "../../../../constants/icons";

const mapStateToProps = (state) => {
  return { promotions: state.promotions };
};

const PartnerAddViewModal = (props) => {
  let { demandQuantity, demandPrice, customPrice, selectedPromotion } =
    props.promotions;
  const [buttonDisable, setButtonDisable] = useState(false);

  const isDemandQuantity =
    demandQuantity === "" ||
    demandQuantity === undefined ||
    demandQuantity === null;
  return (
    <>
      <div className="partner-modal-container">
        <div className="go-back my-4 mt-5">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
      </div>
      <div className="partner-modal-container">
        <div className="d-flex flex-column">
          <div className="mb-1">
            <h2 className="modal-body-text">
              {selectedPromotion?.Product.Name}
            </h2>
          </div>
          <div>
            <h2 className="modal-body-subtext">
              by {selectedPromotion?.Product.Publisher.Name}
            </h2>
            <img src={Icons.greenUnderlineIcon} />
          </div>
          <div className="modal-number-inputs d-flex">
            <NumberInput
              visible
              value={demandQuantity}
              onChange={(ev, val) => {
                props.changeDemandQuantity(ev);
                setButtonDisable(val);
              }}
              label="Quantity"
              intNumber
              minValue={selectedPromotion.MinQuantity}
              maxValue={selectedPromotion.MaxQuantity}
            ></NumberInput>
            <div className="quantity-box">
              <div className="d-flex flex-row  justify-content-end">
                <div className="d-flex flex-column">
                  <span className="quantity-text">QTY Min </span>
                  <span className="quantity-text">Max </span>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span>{selectedPromotion.MinQuantity}</span>
                  <span>{selectedPromotion.MaxQuantity}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-max-description">
            {selectedPromotion.MinQuantity > demandQuantity ? (
              <span>
                Please enter an amount that is larger than the min QTY
              </span>
            ) : null}
            {selectedPromotion.MaxQuantity < demandQuantity ? (
              <span>Please enter an amount that is less than the max QTY</span>
            ) : null}
          </div>
          <div className="d-flex flex-column py-2 fmw-100">
            <div className="d-inline p-2 rounded-1">
              <div className="d-flex">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input form-check-input-partner"
                    type="checkbox"
                    onChange={(ev) => props.changeCustomPrice(ev)}
                    defaultChecked={customPrice}
                    id="props.customPrice"
                  />
                </div>
                <div className="d-inline fmw-100">
                  <div className="d-flex">
                    <label
                      className={`${
                        customPrice
                          ? "modal-body-demand-text form-check-label m-0"
                          : "modal-body-demand-text-inactive form-check-label m-0"
                      } `}
                      htmlFor="props.customPrice"
                    >
                      Demand Special Price
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-number-inputs">
            <NumberInput
              className="modal-number-inputs"
              visible={customPrice}
              value={demandPrice}
              onChange={(ev) => props.changeDemandPrice(ev)}
              label="New Price"
            ></NumberInput>
            {customPrice && (
              <span className="new-price-text">
                Old price was {props.promotions.selectedPromotion.Price}. Your
                saving is{" "}
                {(
                  props.promotions.demandPrice -
                  props.promotions.selectedPromotion.Price
                ).toFixed(2)}
                €
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex flex-column modal-bottom-details  partner-modal-container">
        <h2 className="modal-order-details text-right">Order</h2>
        <h2 className="order-total text-right">
          {isDemandQuantity ? 0 : demandQuantity} x{" "}
          {<NumberFormatter offerPrice={demandPrice} supVisible />}
        </h2>
        <h2 className="mt-1 text-right total-text">Total</h2>
        <h2 className="total text-right">
          {isDemandQuantity || demandQuantity === 0 ? (
            <NumberFormatter offerPrice={0} supVisible />
          ) : (
            <NumberFormatter
              offerPrice={demandPrice}
              offerQuantity={demandQuantity}
              supVisible
            />
          )}
        </h2>
      </div>
      <div className="order-modal-buttons mt-3">
        <Button
          className="modal-button-success"
          bindEvent={() => {
            props.addToBasket(
              demandQuantity,
              demandPrice,
              selectedPromotion?.Product?.Id
            );
          }}
          label="Add to Cart"
          disabled={
            buttonDisable ||
            !demandQuantity ||
            demandQuantity === "0" ||
            !demandPrice ||
            demandPrice === "0"
          }
          buttonIcon={Icons.bigThickIcon}
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(PartnerAddViewModal);
