import React from "react";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import { connect } from "react-redux";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../../../constants/actionTypes";
import {
  Countries_Regions,
  defaultDateFormat,
} from "../../../../../constants/defaults";
import Loader from "../../../../../components/Loader";
import moment from "moment";
import { Icons } from "../../../../../constants/icons";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSend: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "minQuantity",
      value,
    }),
  onCheckPromotion: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "checkPromotion",
      value,
    }),
});
class PublishPromotion extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <PromotionHeader
              product={this.props.promotions.selectedProduct?.Name}
              title={this.props.title}
              goToSelectGame={() => this.props.GoToSelectGame()}
            />
            <div className="container">
              <div className="d-flex mb-1 flex-row align-items-center justify-content-center">
                <div className="d-flex flex-row promotion-box-container">
                  <div className="d-flex flex-column left-column">
                    <span>Avg. Cost</span>
                    <span>Avg. Sales Price</span>
                    <span>Merkur Promotion Standart Price</span>
                    <span>Merkur Promotion Gold Price</span>
                    <span>Merkur Promotion Platinum Price</span>
                    <span>
                      {this.props.promotions.selectedProduct?.Provider.Name +
                        " "}
                      Promotion Time
                    </span>
                    <span>Merkur Promotion Time</span>
                    <span>OS</span>
                    <span>Platforms</span>
                    <span>Available Countries</span>
                  </div>
                  <div className="d-flex flex-column right-column">
                    {this.props.promotions.calculatedAverage !== null ? (
                      <span>
                        {Math.round(
                          this.props.promotions.calculatedAverage?.Cost * 100
                        ) / 100}{" "}
                        EUR
                      </span>
                    ) : (
                      "-"
                    )}
                    {this.props.promotions.calculatedAverage?.Price !== null ? (
                      <span>
                        {Math.round(
                          this.props.promotions.calculatedAverage?.Price * 100
                        ) / 100}{" "}
                        EUR
                      </span>
                    ) : (
                      "-"
                    )}
                    <div className="d-flex flex-row justify-content-start">
                      {this.props.promotions.tier1Price &&
                      this.props.promotions.calculatedAverage?.Cost ? (
                        <div>
                          <span>
                            {Math.round(
                              this.props.promotions.tier1Price * 100
                            ) / 100}{" "}
                            EUR
                          </span>

                          <span className="ms-1">
                            (Profit:{" "}
                            {(
                              ((this.props.promotions.tier1Price -
                                this.props.promotions.calculatedAverage?.Cost) *
                                100) /
                              this.props.promotions.calculatedAverage?.Cost
                            ).toFixed(2)}
                            %)
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {this.props.promotions.tier2Price &&
                      this.props.promotions.calculatedAverage?.Cost ? (
                        <div>
                          <span>
                            {Math.round(
                              this.props.promotions.tier2Price * 100
                            ) / 100}{" "}
                            EUR
                          </span>

                          <span className="ms-1">
                            (Profit:{" "}
                            {(
                              ((this.props.promotions.tier2Price -
                                this.props.promotions.calculatedAverage?.Cost) *
                                100) /
                              this.props.promotions.calculatedAverage?.Cost
                            ).toFixed(2)}
                            %)
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {this.props.promotions.tier3Price &&
                      this.props.promotions.calculatedAverage?.Cost ? (
                        <div>
                          <span>
                            {Math.round(
                              this.props.promotions.tier3Price * 100
                            ) / 100}{" "}
                            EUR
                          </span>

                          <span className="ms-1">
                            (Profit:{" "}
                            {(
                              ((this.props.promotions.tier3Price -
                                this.props.promotions.calculatedAverage?.Cost) *
                                100) /
                              this.props.promotions.calculatedAverage?.Cost
                            ).toFixed(2)}
                            %)
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    {this.props.promotions.selectedProduct?.ValidFrom &&
                    this.props.promotions.selectedProduct?.ValidTo ? (
                      <div className="d-flex flex-row justify-content-start">
                        <span>
                          {moment(
                            this.props.promotions.selectedProduct?.ValidFrom
                          ).format(defaultDateFormat + "(H:mm:ss)")}{" "}
                          /1
                          {" " +
                            moment(
                              this.props.promotions.selectedProduct?.ValidTo
                            ).format(defaultDateFormat + "(H:mm:ss)")}
                        </span>
                        <span className="ms-1">
                          (
                          {Math.floor(
                            (Date.parse(
                              this.props.promotions.selectedProduct?.ValidTo
                            ) -
                              Date.parse(
                                this.props.promotions.selectedProduct?.ValidFrom
                              )) /
                              86400000
                          )}{" "}
                          Day
                          {Math.floor(
                            (Date.parse(
                              this.props.promotions.selectedProduct?.ValidTo
                            ) -
                              Date.parse(
                                this.props.promotions.selectedProduct?.ValidFrom
                              )) /
                              86400000
                          ) > 1
                            ? "s"
                            : ""}
                          )
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                    {this.props.promotions.validFrom &&
                    this.props.promotions.validTo ? (
                      <div className="d-flex flex-row justify-content-start">
                        <span
                          className={
                            new Date(
                              this.props.promotions.validFrom.date
                            ).getTime() <
                              new Date(
                                this.props.promotions.selectedProduct?.ValidFrom
                              ).getTime() ||
                            new Date(
                              this.props.promotions.validTo.date
                            ).getTime() >
                              new Date(
                                this.props.promotions.selectedProduct?.ValidTo
                              ).getTime()
                              ? !this.props.promotions.selectedProduct
                                  ?.validFrom &&
                                !this.props.promotions.selectedProduct?.validTo
                                ? ""
                                : "text-danger"
                              : ""
                          }
                        >
                          {moment(this.props.promotions.validFrom.date).format(
                            defaultDateFormat + "(H:mm:ss)"
                          )}{" "}
                          /
                          {" " +
                            moment(this.props.promotions.validTo.date).format(
                              defaultDateFormat + "(H:mm:ss)"
                            )}
                        </span>
                        <span className="ms-1">
                          (
                          {Math.floor(
                            (Date.parse(this.props.promotions.validTo.date) -
                              Date.parse(
                                this.props.promotions.validFrom.date
                              )) /
                              86400000
                          )}{" "}
                          Day
                          {Math.floor(
                            (Date.parse(this.props.promotions.validTo.date) -
                              Date.parse(
                                this.props.promotions.validFrom.date
                              )) /
                              86400000
                          ) > 1
                            ? "s"
                            : ""}
                          )
                        </span>
                      </div>
                    ) : (
                      "-"
                    )}
                    <span>
                      {this.props.promotions.setOS.map((item, key) => {
                        if (item == "Windows") {
                          return (
                            <img key={key} src={Icons.Windows} width="30" />
                          );
                        } else if (item == "MacOS") {
                          return <img key={key} src={Icons.Macos} width="30" />;
                        } else {
                          return <img key={key} src={Icons.Linux} width="30" />;
                        }
                      })}
                    </span>
                    <span>
                      {this.props.promotions.setPlatforms?.join(", ")}
                    </span>

                    {this.props.promotions.changePercentage.map((item, key) => {
                      return (
                        item.weight !== 0 && (
                          <div
                            className="d-flex flex-row justify-content-start"
                            key={key}
                          >
                            <span
                              className={
                                "flag-icon mr-2 flag-icon-" +
                                item.code.toLowerCase()
                              }
                            ></span>
                            <span className="ms-1">
                              {
                                Countries_Regions.filter(
                                  (f) => f.alpha2 === item.code
                                )[0]?.name
                              }
                            </span>
                            <span className="ms-1">({item.weight}%)</span>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="terms-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isActive"
                    defaultChecked={this.props.promotions.checkPromotion}
                    onChange={(ev) =>
                      this.props.onCheckPromotion(ev.target.checked)
                    }
                  />
                  <label className="terms-check-label" htmlFor="isActive">
                    I have checked all the details of the promotion.
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishPromotion);
