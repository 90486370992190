import React from "react";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import BlankPage from "../../components/BlankPage";
import Content from "../../components/containers/Content";
import { connect } from "react-redux";
import {
  UPCOMING_PROMOTION,
  COMPANIES_PAGE_LOADED,
} from "../../constants/actionTypes";
import Pager from "../../components/Pager";
import base64 from "base-64";
import Filter from "../../components/Filter";
import { defaultDateFormat } from "../../constants/defaults";
import moment from "moment";
import Button from "../../components/common/Button";
import MerModal from "../../components/common/MerModal";
import services from "../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    companies: state.companies,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  upcomingPromotionsOnLoad: (payload) => {
    dispatch({ type: UPCOMING_PROMOTION, payload });
  },
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
});

class UpcomingPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.getCompanies();
    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      pageindex: 0,
      tableIsLoading: false,
      showModal: false,
      selectedPromotion: undefined,
    };
  }
  componentDidMount() {
    document.title = "Upcoming Promotions";
    this.getUpcomingPromotions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getCompanies = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    this.props.companiesOnLoad(
      Promise.all([
        services.company.getCompanies(keywords, pageindex, pagesize),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  getUpcomingPromotions = (params) => {
    this.setState({ tableIsLoading: true });
    const { pageindex = this.state.pageindex, pagesize = this.props.pageSize } =
      params || {};
    this.props.upcomingPromotionsOnLoad(
      Promise.all([services.campaigns.upcomingPromotions(pageindex, pagesize)])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  changeUpcomingStatus = (id) => {
    services.campaigns
      .changeUpcomingStatus(id, this.state.selectedPromotion.AuthorizedPartners)
      .then(() => {
        this.getUpcomingPromotions({ pageindex: this.state.pageindex });
        this.setState({ showModal: false });
        return true;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  onFilter = (params) => {
    let { filter, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;

    this.getUpcomingPromotions({
      pageindex: pageindex,
    });
  };

  onChangePageIndex = (pageindex) => {
    this.setState({ tableIsLoading: true, pageindex: pageindex });
    this.getUpcomingPromotions({
      pageindex: pageindex,
    });
  };

  selectUpcomingPromotionPartner = (value, name, checked) => {
    let selectedPromotion = this.state.selectedPromotion;
    let findingPartner = selectedPromotion.AuthorizedPartners.find(
      (f) => f.PartnerId === value
    );

    if (findingPartner) {
      if (!checked)
        selectedPromotion.AuthorizedPartners =
          selectedPromotion.AuthorizedPartners.filter(
            (item) => item.PartnerId !== value
          );
    } else if (checked)
      selectedPromotion.AuthorizedPartners.push({
        PartnerId: value,
        PartnerName: name,
      });

    this.setState({ selectedPromotion: selectedPromotion });
  };

  render() {
    const { upcomingPromotions } = this.props.promotions;
    const { companyList } = this.props.companies;

    return (
      <Content pageTitle="Upcoming Promotions">
        {upcomingPromotions == undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              hideSearch
              onFilter={(ev) => this.onFilter(ev)}
            ></Filter>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : upcomingPromotions[0]?.Items.length === 0 ? (
              <BlankPage description="There is no upcoming promotion." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {upcomingPromotions[0]?.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">
                          Publisher Name
                        </th>
                        <th className="bg-transparent border-0 ">Start Date</th>
                        <th className="bg-transparent border-0 ">End Date</th>
                        <th className="bg-transparent border-0 ">
                          Partner Authorized
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingPromotions[0]?.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td>
                              <p>{item.PublisherName}</p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidFrom).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidTo).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                            <td className="ps-0">
                              <Button
                                label="Edit Partners"
                                className="confirm-button px-4"
                                bindEvent={() =>
                                  this.setState({
                                    showModal: true,
                                    selectedPromotion: item,
                                  })
                                }
                              ></Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.pageindex}
                  totalPages={upcomingPromotions[0]?.TotalPages}
                  totalCount={upcomingPromotions[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
          </div>
        )}

        <MerModal
          id="order-confirm"
          showModal={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <div className="upcoming-promotions-partners-model p-4">
            <div className="order-confirm-title">
              <label>
                {this.state.selectedPromotion?.PublisherName}: Authorized
                Partners
              </label>
            </div>
            <div className="order-confirm-description">
              <ul className="col-md-12 checkboxes">
                {companyList !== undefined &&
                  companyList[0]?.Items?.map((company, key) => {
                    return (
                      <li className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={company.Id}
                          onChange={(ev) =>
                            this.selectUpcomingPromotionPartner(
                              ev.target.value,
                              company.Name,
                              ev.target.checked
                            )
                          }
                          defaultChecked={
                            this.state.selectedPromotion?.AuthorizedPartners.find(
                              (f) => f.PartnerId === company.Id
                            ) !== undefined
                          }
                          id={"chk_" + key}
                        />
                        <label htmlFor={"chk_" + key}>{company.Name}</label>
                      </li>
                    );
                  })}
              </ul>
              <div className="d-flex justify-content-end">
                <Button
                  label="Save"
                  className="confirm-button px-4"
                  bindEvent={() =>
                    this.changeUpcomingStatus(this.state.selectedPromotion.Id)
                  }
                ></Button>
              </div>
            </div>
            <div></div>
          </div>
        </MerModal>
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingPromotions);
