import merkurLogoIcon from "../assets/images/brand/merkur-logo-icon.svg";
import goBackIcon from "../assets/images/icons/go_back_icon.svg";
import maskIcon from "../assets/images/icons/mask_icon.svg";
import messageBoxIcon from "../assets/images/icons/message_box_icon.svg";
import greenUnderlineIcon from "../assets/images/icons/green-underline-icon.svg";
import loadingIcon from "../assets/images/icons/loading-icon.svg";
import bigThickIcon from "../assets/images/icons/big-thick-icon.svg";
import filterSearchIcon from "../assets/images/icons/filter-search-icon.svg";
import circleIcon from "../assets/images/icons/circle-icon.svg";
import leftArrowIcon from "../assets/images/icons/left-arrow-icon.svg";
import logoutIcon from "../assets/images/icons/logout-icon.svg";
import circleSapphireIcon from "../assets/images/icons/circle-sapphire-icon.svg";
import circleCarnationIcon from "../assets/images/icons/circle-carnation-icon.svg";
import plusIcon from "../assets/images/icons/plus-icon.svg";
import addUserIcon from "../assets/images/icons/add-user-icon.svg";
import editUserIcon from "../assets/images/icons/edit-user-icon.svg";
import whitePlusIcon from "../assets/images/icons/white-plus-icon.svg";
import addFromGoogleSheetIcon from "../assets/images/icons/add_from_google_sheet_icon.svg";
import updateGoogleSheetIcon from "../assets/images/icons/update_google_sheet_icon.svg";
import nullIcon from "../assets/images/icons/null_icon.svg";
import copyIcon from "../assets/images/icons/copy_icon.svg";
import pdfIcon from "../assets/images/icons/pdf-icon.svg";
import navbarIconPermission from "../assets/images/icons/navbar-icon-permission.svg";
import infoIcon from "../assets/images/icons/info-icon.svg";
import ellipsisMenuIcon from "../assets/images/icons/ellipsis-menu-icon.svg";

import iconHome from "../assets/images/icons/navbar-icon-home.svg";
import iconPromotions from "../assets/images/icons/navbar-icon-promotions.svg";
import iconOrders from "../assets/images/icons/navbar-icon-orders.svg";
import iconInvoice from "../assets/images/icons/navbar-icon-invoices.svg";
import iconCreditLimit from "../assets/images/icons/navbar-icon-credit-limit.svg";
import iconMessages from "../assets/images/icons/navbar-icon-messages.svg";
import iconUsers from "../assets/images/icons/navbar-icon-users.svg";
import iconUser from "../assets/images/icons/navbar-icon-user.svg";
import iconUsersFromMenu from "../assets/images/icons/navbar-icon-users-menu.svg";
import iconProviderProducts from "../assets/images/icons/navbar-icon-provider-products.svg";
import iconAllocations from "../assets/images/icons/navbar-icon-allocations.svg";
import iconCompanies from "../assets/images/icons/navbar-icon-companies.svg";
import iconInventories from "../assets/images/icons/navbar-icon-inventories.svg";
import iconUpcomingPromotions from "../assets/images/icons/navbar-icon-upcoming-promotions.svg";
import iconActivePromotions from "../assets/images/icons/navbar-icon-active-promotions.svg";
import iconTopSellers from "../assets/images/icons/navbar-icon-top-sellers.svg";
import iconReport from "../assets/images/icons/navbar-icon-report.svg";
import iconProductReport from "../assets/images/icons/navbar-icon-product-report.svg";
import IconDirectSales from "../assets/images/icons/navbar-icon-direct-sales.svg";
import IconNew from "../assets/images/icons/navbar-icon-new.svg";
import IconEdit from "../assets/images/icons/edit-icon.svg";
import IconAccount from "../assets/images/icons/account-module-icon.svg";

import rightArrow135Icon from "../assets/images/icons/withdraw_icon.svg";
import minusIcon from "../assets/images/icons/closed_icon.svg";
import thickIcon from "../assets/images/icons/confirmed_completed_icon.svg";
import crossIcon from "../assets/images/icons/failed_cancelled_icon.svg";
import processingIcon from "../assets/images/icons/processing_icon.svg";
import attentionIcon from "../assets/images/icons/rebidding_icon.svg";
import cancelIcon from "../assets/images/icons/refused_rejected_icon.svg";
import rightArrow45Icon from "../assets/images/icons/revoked_icon.svg";
import dollarIcon from "../assets/images/icons/refunded_icon.svg";
import rightArrowIcon from "../assets/images/icons/submitted_icon.svg";
import collapseArrowDownIcon from "../assets/images/icons/collapse_arrow_down_icon.svg";
import collapseArrowUpIcon from "../assets/images/icons/collapse_arrow_up_icon.svg";
import createPackageIcon from "../assets/images/icons/create-package-icon.svg";
import sendPackageIcon from "../assets/images/icons/send-package-icon.svg";
import basketIcon from "../assets/images/basket.svg";
import seeDetailIcon from "../assets/images/icons/see-detail-icon.svg";
import keyIcon from "../assets/images/icons/key-icon.svg";
import reprocessIcon from "../assets/images/icons/reprocess-icon.svg";
import sendInvoiceIcon from "../assets/images/icons/send-invoice-icon.svg";
import isBilledIcon from "../assets/images/icons/is-billed-icon.svg";
import transferredIcon from "../assets/images/icons/transferred-icon.svg";
import paginationFirst from "../assets/images/icons/icon-pagination-first.svg";
import paginationLast from "../assets/images/icons/icon-pagination-last.svg";
import inventoryReportIcon from "../assets/images/icons/icon-inventory-report.svg";

import arrowUpGreenIcon from "../assets/images/icons/up_big_arrow_icon.svg";
import permissionThrashIcon from "../assets/images/icons/permission-thrash-icon.svg";
import permissionEditIcon from "../assets/images/icons/permission-edit-icon.svg";
import arrowDownRedIcon from "../assets/images/icons/down_big_arrow_icon.svg";
import equalGrayIcon from "../assets/images/icons/equal_icon.svg";
import removeIcon from "../assets/images/icons/remove_icon.svg";
import retryIcon from "../assets/images/icons/retry_icon.svg";
import viewDetailIcon from "../assets/images/icons/view_detail_icon.svg";
import excelIcon from "../assets/images/icons/excel_icon.svg";
import settingsIcon from "../assets/images/icons/settings-icon.svg";

import Linux from "../assets/images/os/linux.svg";
import Windows from "../assets/images/os/windows.svg";
import Macos from "../assets/images/os/macos.svg";

import promotionSearchIcon from "../assets/images/icons/promotion_search_icon.svg";
import publishFinishedIcon from "../assets/images/icons/publish-finished-icon.svg";

// eslint-disable-next-line import/prefer-default-export
export const Icons = {
  //general use
  merkurLogoIcon: merkurLogoIcon,
  goBackIcon: goBackIcon,
  maskIcon: maskIcon,
  messageBoxIcon: messageBoxIcon,
  greenUnderlineIcon: greenUnderlineIcon,
  loadingIcon: loadingIcon,
  bigThickIcon: bigThickIcon,
  filterSearchIcon: filterSearchIcon,
  circleIcon: circleIcon,
  leftArrowIcon: leftArrowIcon,
  logoutIcon: logoutIcon,
  circleSapphireIcon: circleSapphireIcon,
  circleCarnationIcon: circleCarnationIcon,
  plusIcon: plusIcon,
  addUserIcon: addUserIcon,
  editUserIcon: editUserIcon,
  whitePlusIcon: whitePlusIcon,
  addFromGoogleSheetIcon: addFromGoogleSheetIcon,
  updateGoogleSheetIcon: updateGoogleSheetIcon,
  pdfIcon: pdfIcon,
  nullIcon: nullIcon,
  copyIcon: copyIcon,
  settingsIcon: settingsIcon,
  //navbar icons
  iconHome: iconHome,
  iconPromotions: iconPromotions,
  iconOrders: iconOrders,
  iconInvoice: iconInvoice,
  iconCreditLimit: iconCreditLimit,
  iconMessages: iconMessages,
  iconUsers: iconUsers,
  iconUser: iconUser,
  iconUsersFromMenu: iconUsersFromMenu,
  iconProviderProducts: iconProviderProducts,
  iconAllocations: iconAllocations,
  iconCompanies: iconCompanies,
  iconInventories: iconInventories,
  iconUpcomingPromotions: iconUpcomingPromotions,
  iconActivePromotions: iconActivePromotions,
  iconReport: iconReport,
  iconProductReport: iconProductReport,
  iconTopSellers: iconTopSellers,
  IconDirectSales: IconDirectSales,
  IconNew: IconNew,
  IconEdit: IconEdit,
  NavbarIconPermission: navbarIconPermission,
  IconAccount: IconAccount,
  //navbar icon
  basketIcon: basketIcon,

  //order status and buttons icons
  processingIcon: processingIcon,
  thickIcon: thickIcon,
  attentionIcon: attentionIcon,
  cancelIcon: cancelIcon,
  rightArrowIcon: rightArrowIcon,
  rightArrow45Icon: rightArrow45Icon,
  rightArrow135Icon: rightArrow135Icon,
  minusIcon: minusIcon,
  crossIcon: crossIcon,
  dollarIcon: dollarIcon,
  collapseArrowDownIcon: collapseArrowDownIcon,
  collapseArrowUpIcon: collapseArrowUpIcon,
  createPackageIcon: createPackageIcon,
  sendPackageIcon: sendPackageIcon,
  reprocessIcon: reprocessIcon,
  seeDetailIcon: seeDetailIcon,
  keyIcon: keyIcon,
  sendInvoiceIcon: sendInvoiceIcon,
  isBilledIcon: isBilledIcon,
  transferredIcon: transferredIcon,
  paginationFirst: paginationFirst,
  paginationLast: paginationLast,
  inventoryReportIcon: inventoryReportIcon,
  ellipsisMenuIcon: ellipsisMenuIcon,
  //order detail
  permissionEditIcon: permissionEditIcon,
  permissionThrashIcon: permissionThrashIcon,
  arrowUpGreenIcon: arrowUpGreenIcon,
  arrowDownRedIcon: arrowDownRedIcon,
  equalGrayIcon: equalGrayIcon,
  removeIcon: removeIcon,
  retryIcon: retryIcon,
  viewDetailIcon: viewDetailIcon,
  excelIcon: excelIcon,
  infoIcon: infoIcon,
  //OS
  Linux: Linux,
  Macos: Macos,
  Windows: Windows,

  //promotions
  promotionSearchIcon: promotionSearchIcon,
  publishFinishedIcon: publishFinishedIcon,
};
