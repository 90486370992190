import React from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";

const ManagementOrderSendToInventoryConfirm = (props) => {
  return (
    <div className="order-confirm-modal send-inventory-confirm">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>
          All keys will be sent to the inventory, are you sure to proceed?
        </label>
      </div>
      <div className="order-confirm-description mb-3">
        <span>If you close this popup, your order status is not change.</span>
      </div>
      {props.isBilled && (
        <div className="order-confirm-description">
          <li>
            Invoice has been created, please make sure you created a credit
            note.
          </li>
        </div>
      )}
      {props.packages.length > 0 && (
        <div className="order-confirm-description">
          <li>
            Partner has been received keys, these keys will be sent to
            inventory.
          </li>
        </div>
      )}
      <div className="d-flex  justify-content-center">
        <Button
          disabled={props.btnDisable}
          className="order-confirm-yes-button"
          bindEvent={() => props.sendToInventory()}
          label="Send To Inventory"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div className="d-flex  justify-content-center">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.showModal()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderSendToInventoryConfirm;
