import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { common } from "../constants/defaults";
import { MENU_STATUS_CHANGE, RETURN_URL } from "../constants/actionTypes";
import { Icons } from "../constants/icons";
import CheckPermission from "../constants/permission";

const mapStateToProps = (state) => {
  return {
    returnurl: state.common.returnurl,
    menuCollapse: state.common.menuCollapse,
    deviceInfo: state.common.device_info,
    userScope: state.auth.currentUser.scope,
    mypermission: state.permission.mypermission,
  };
};

let itemIcon = "";

const mapDispatchToProps = (dispatch) => ({
  onReturnUrl: (returnurl) => dispatch({ type: RETURN_URL, returnurl }),
  menuCollapseChange: (menuCollapse) =>
    dispatch({ type: MENU_STATUS_CHANGE, menuCollapse }),
});
class NavItem extends React.Component {
  permissionControl = () => {
    return this.props.permission
      ? CheckPermission(this.props.permission, this.props.mypermission)
      : true;
  };

  render() {
    switch (this.props.icon) {
      case "home":
        itemIcon = Icons.iconHome;
        break;
      case "orders":
        itemIcon = Icons.iconOrders;
        break;
      case "promotions":
        itemIcon = Icons.iconPromotions;
        break;
      case "credit-limit":
        itemIcon = Icons.iconCreditLimit;
        break;
      case "invoice":
        itemIcon = Icons.iconInvoice;
        break;
      case "messages":
        itemIcon = Icons.iconMessages;
        break;
      case "users":
        itemIcon = Icons.iconUsersFromMenu;
        break;
      case "provider-products":
        itemIcon = Icons.iconProviderProducts;
        break;
      case "allocations":
        itemIcon = Icons.iconAllocations;
        break;
      case "companies":
        itemIcon = Icons.iconCompanies;
        break;
      case "inventories":
        itemIcon = Icons.iconInventories;
        break;
      case "active-promotions":
        itemIcon = Icons.iconActivePromotions;
        break;
      case "upcoming-promotions":
        itemIcon = Icons.iconUpcomingPromotions;
        break;
      case "top-sellers":
        itemIcon = Icons.iconTopSellers;
        break;
      case "product-report":
        itemIcon = Icons.iconProductReport;
        break;
      case "direct-sales":
        itemIcon = Icons.IconDirectSales;
        break;
      case "permission":
        itemIcon = Icons.NavbarIconPermission;
        break;
      case "inventory-report":
        itemIcon = Icons.inventoryReportIcon;
        break;
      case "settings":
        itemIcon = Icons.settingsIcon;
        break;
    }

    if (this.permissionControl())
      if (
        this.props.accessScope?.includes(this.props.userScope[0]) ||
        this.props.accessScope === common
      )
        return (
          <li
            className={
              "nav-item " +
              (this.props.returnurl.includes(this.props.url) ? "active" : "")
            }
          >
            <div className="nav-link-container h-100">
              <Link
                to={this.props.url}
                className={
                  "nav-link d-flex align-items-center w-100" +
                  (this.props.returnurl.includes(this.props.url)
                    ? "  disabled-link"
                    : "")
                }
                onClick={() => {
                  this.props.onReturnUrl(this.props.url);
                  this.props.menuCollapseChange(true);
                }}
              >
                {this.props.icon !== undefined ? (
                  <span className="sidebar-icon">
                    <img
                      src={itemIcon}
                      alt={this.props.item}
                      width={this.props.width}
                    />
                  </span>
                ) : (
                  ""
                )}

                <span className="mt-1 ms-1 sidebar-text h-100 p-0 m-0 d-flex align-items-center">
                  {this.props.text}
                </span>
                {this.props.new !== undefined &&
                this.props.returnurl !== this.props.url ? (
                  <span className="new-icon px-2 ">
                    <img src={Icons.IconNew} alt={this.props.item} />
                  </span>
                ) : (
                  ""
                )}
              </Link>
            </div>
          </li>
        );
      else return <div />;
    return <div />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
