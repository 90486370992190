import React from "react";
import { connect } from "react-redux";
import { REPORT_ORDER_ITEMS_COUNT_BY_STATE } from "../../constants/actionTypes";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../../components/Loader";
import Pager from "../../components/Pager";
import Content from "../../components/containers/Content";
import "react-datepicker/dist/react-datepicker.css";
import services from "../../api/index";

const mapStateToProps = (state) => {
  return { report: state.report, pageSize: state.common.pageSize };
};

const mapDispatchToProps = (dispatch) => ({
  orderItemsCountByStateOnLoad: (payload) =>
    dispatch({ type: REPORT_ORDER_ITEMS_COUNT_BY_STATE, payload }),
});

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderItemsCountByStatePageindex: 0,
      orderItemsCountByStateStatus: "",
      validDateFrom: undefined,
      validDateTo: undefined,
      activeTab: "OutOfStock",
    };
  }

  componentDidMount() {
    document.title = "Merkur";

    this.getOrderItemsCountByState();
  }

  getOrderItemsCountByState(params) {
    const {
      pageIndex = 0,
      pageSize = this.props.pageSize,
      state = "OutOfStock",
    } = params || {};
    this.setState({
      orderItemsCountByStateStatus: state,
      activeTab: state,
    });
    this.props.orderItemsCountByStateOnLoad(
      services.report
        .getOrderItemsCountByState(pageIndex, pageSize, state)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  render() {
    const filterPassedMinTime = (time) => {
      const currentDate =
        this.state.validDateTo !== undefined
          ? new Date(this.state.validDateTo)
          : new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() > selectedDate.getTime();
    };
    const filterPassedMaxTime = (time) => {
      const currentDate =
        this.state.validDateFrom !== undefined
          ? new Date(this.state.validDateFrom)
          : new Date(time);
      const selectedDate =
        this.state.validDateFrom !== undefined ? new Date(time) : new Date();
      return currentDate.getTime() < selectedDate.getTime();
    };
    return (
      <Content pageTitle="Overview">
        <div className="row">
          <div className="card px-5 py-5">
            <div className="p-0">
              <label className="dashboard-title-text">
                <b>Order Item Count by Status</b>
              </label>
            </div>
            <div className="d-flex flex-row justify-content-between border-bottom">
              <ul className="nav nav-tabs ">
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "OutOfStock" ? "active" : "")
                    }
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "OutOfStock",
                      })
                    }
                  >
                    Out of Stock
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "Error" ? "active" : "")
                    }
                    aria-current="page"
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "Error",
                      })
                    }
                  >
                    Error
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "Refunded" ? "active" : "")
                    }
                    aria-current="page"
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "Refunded",
                      })
                    }
                  >
                    Refunded
                  </a>
                </li>
              </ul>
              <div className="table-total-count mt-3">
                Total Count:
                {this.props.report?.reportOrderItemsCountByState &&
                  this.props.report?.reportOrderItemsCountByState?.TotalCount}
              </div>
            </div>

            {this.props.report?.reportOrderItemsCountByState === undefined ? (
              <Loader />
            ) : (
              <div className="table-responsive-xl p-0">
                <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
                  <thead>
                    <tr>
                      <th>
                        <p>Product SKU</p>
                      </th>
                      <th>
                        <p>Product Name</p>
                      </th>
                      <th>
                        <p>Count</p>
                      </th>
                      <th>
                        <p>Last Trying Date</p>
                      </th>

                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.report?.reportOrderItemsCountByState?.Items
                      .length === 0 ? (
                      <label className="order-detail-table-no-item">
                        There is no available key !
                      </label>
                    ) : (
                      this.props.report?.reportOrderItemsCountByState?.Items.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p>{item.ProductSku ?? "-"}</p>
                              </td>
                              <td>
                                <p>{item.ProductName ?? "-"}</p>
                              </td>
                              <td>
                                <p>{item.Count ?? "-"}</p>
                              </td>
                              <td>
                                <p>
                                  {moment
                                    .utc(item?.LastTryingDate)
                                    .format("DD.MM.yyyy hh:mm:ss")}
                                </p>
                              </td>
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </table>
                <Pager
                  pageIndex={
                    this.props.report?.reportOrderItemsCountByState?.PageIndex
                  }
                  totalPages={
                    this.props.report?.reportOrderItemsCountByState?.TotalPages
                  }
                  totalCount={
                    this.props.report?.reportOrderItemsCountByState?.TotalCount
                  }
                  onChange={(pageIndex) =>
                    this.getOrderItemsCountByState({
                      pageindex: pageIndex,
                      state: this.state.orderItemsCountByStateStatus,
                    })
                  }
                />
              </div>
            )}
          </div>
          <iframe
            title="dash"
            width="960"
            height="720"
            src="https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/011758244598/dashboards/fd1e4d42-bbde-4c6e-9116-467c8af432a3?directory_alias=merkurhub"
          ></iframe>
        </div>
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
