import React from "react";
import { connect } from "react-redux";
import {
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  ORDER_LIST,
  PROMOTION_DETAIL,
  REDIRECT,
} from "../../../constants/actionTypes";
import {
  dateTypes,
  defaultDateFormat,
  OrderStatus,
} from "../../../constants/defaults";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import Filter from "../../../components/Filter";
import NumberFormatter from "../../../components/common/NumberFormatter";
import { Endpoints } from "../../../constants/endpoints";
import Pager from "../../../components/Pager";
import { Link } from "react-router-dom";
import Button from "../../../components/common/Button";
import Content from "../../../components/containers/Content";
import { Icons } from "../../../constants/icons";
import { fixTimezoneOffset } from "../../../functions/dateConverter";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    order: state.order,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  promotionOnLoad: (payload) => dispatch({ type: PROMOTION_DETAIL, payload }),

  ordersListOnLoad: (payload) => dispatch({ type: ORDER_LIST, payload }),
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
  onChangeProperty: (key, value) =>
    dispatch({ type: MANAGEMENT_PROMOTION_UPDATE_FIELD, key, value }),
});

class PromotionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: props.match.params.sort,
      pageindex: props.match.params.page ? props.match.params.page : 0,
      statusList: undefined,
      promotionid: this.props.match.params.promotionid,
    };
  }

  componentDidMount() {
    this.getCampaignFromManagement();
    this.getOrders();
  }

  getCampaignFromManagement(params) {
    const { promotionid = this.state.promotionid } = params || {};
    return this.props.promotionOnLoad(
      Promise.all([services.campaigns.getCampaignFromManagement(promotionid)])
        .then((res) => {
          let promotion = res[0];

          let allcountries = [];
          promotion.Product.Prices.map((m) =>
            m.Countries.map((c) => allcountries.push(c))
          );
          this.props.onChangeProperty("allcountries", allcountries);

          return promotion;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  editPromotion = () => {
    let promotion = this.props.promotions.promotionDetail;

    let url = Endpoints.Management.EditPromotion.url.replace(
      ":" + Endpoints.Management.EditPromotion.url.split(":")[1],
      promotion.Id
    );
    this.props.onRedirect(url);
  };

  getOrders(params) {
    const {
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      states = this.state.statusList,
      keywords = this.state.promotionid,
      productSku,
      priceFrom,
      priceTo,
      createdAtFrom,
      createdAtTo,
      sortBy,
      IsBilled = "",
      partnerIds,
      productPublisher = "",
    } = params || {};
    this.setState({ pageindex: pageindex, statusList: states });
    this.props.ordersListOnLoad(
      Promise.all([
        services.orders.getManagementOrders(
          pageindex,
          pagesize,
          states,
          keywords,
          productSku,
          priceFrom,
          priceTo,
          createdAtFrom,
          createdAtTo,
          sortBy === "0" ? "" : sortBy,
          IsBilled,
          partnerIds,
          productPublisher
        ),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;

    if (sortBy !== undefined) {
      if (sortBy === this.state.sortBy) sortBy = `-${sortBy}`;
      this.setState({ sortBy });
    } else sortBy = this.state.sortBy;
    this.getOrders({
      keywords: this.state.promotionid,
      partnerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      priceTo: ev?.Price?.up,
      priceFrom: ev?.Price?.down,
      createdAtFrom: ev?.DateRange?.min,
      createdAtTo: ev?.DateRange?.max,
      states: ev?.Status?.length !== 0 ? ev?.Status?.join(",") : "",
      sortBy: sortBy,
      pageindex: pageindex,
    });
  }

  render() {
    return (
      <Content pageTitle={this.props.promotions.promotionDetail?.Product?.Name}>
        {this.props.promotions.promotionDetail === undefined ||
        this.props.order.orderList === undefined ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <div className="col-12 col-xl-4">
                <div className="row">
                  <div className="col-12">
                    <div className="card py-4 px-5">
                      <div className="d-flex justify-content-between order-history">
                        <div className="mt-3 order-history-title">
                          Promotion Status
                        </div>
                        <div
                          className={`order-history-quantity-status ${
                            this.props.promotions.promotionDetail?.Published ===
                            true
                              ? "yellowish-green"
                              : "carnation"
                          }`}
                        >
                          {this.props.promotions.promotionDetail?.Published
                            ? "Published"
                            : "Unpublished"}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between dashed-line">
                        <div className="order-history">Voidu Cost</div>
                        <div className="order-history">
                          <NumberFormatter
                            offerPrice={
                              this.props.promotions.promotionDetail?.Cost
                            }
                            supVisible
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between order-history">
                        <div className="mt-2 order-history-title">Standart</div>
                        <div className="d-flex flex-row justify-content-end">
                          <div className="order-history-quantity-price">
                            <NumberFormatter
                              offerPrice={
                                this.props.promotions.promotionDetail
                                  ?.Tier1Price
                              }
                              supVisible
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between order-history">
                        <div className="mt-2 order-history-title">Gold</div>
                        <div className="d-flex flex-row justify-content-end">
                          <div className="order-history-quantity-price">
                            <NumberFormatter
                              offerPrice={
                                this.props.promotions.promotionDetail
                                  ?.Tier2Price
                              }
                              supVisible
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between order-history">
                        <div className="mt-2 order-history-title">Platinum</div>
                        <div className="d-flex flex-row justify-content-end">
                          <div className="order-history-quantity-price">
                            <NumberFormatter
                              offerPrice={
                                this.props.promotions.promotionDetail
                                  ?.Tier3Price
                              }
                              supVisible
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div>
                      <div className="card py-4 px-5">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="order-history">
                            <div className="order-history-title">
                              {" "}
                              {this.props.promotions.promotionDetail?.Product
                                ?.Provider?.Name + " "}
                              Start Date <sup>UTC</sup>
                            </div>
                            <div>
                              {moment(
                                this.props.promotions.promotionDetail?.Product
                                  ?.ValidFrom
                              ).format(defaultDateFormat + "\tH:mm:ss")}{" "}
                            </div>
                          </div>
                          <div className="order-history mx-2">
                            <div className="order-history-title">
                              {" "}
                              {this.props.promotions.promotionDetail?.Product
                                ?.Provider?.Name + " "}
                              End Date <sup>UTC</sup>
                            </div>
                            <div>
                              {moment(
                                this.props.promotions.promotionDetail?.Product
                                  ?.ValidTo
                              ).format(defaultDateFormat + "\tH:mm:ss")}{" "}
                            </div>
                          </div>
                          <div className="order-history">
                            <div className="order-history-title">
                              {" "}
                              Game Release Date <sup>UTC</sup>
                            </div>
                            <div>
                              {moment(
                                this.props.promotions.promotionDetail?.Product
                                  ?.ReleaseDate
                              ).format(defaultDateFormat + "\tH:mm:ss")}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-8">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="card py-4 px-5 d-flex flex-row justify-content-between">
                      <div>
                        <div className="order-details-summary-left">
                          <div className="order-history">
                            <div className="order-history-title">Provider</div>
                            <div>
                              {
                                this.props.promotions.promotionDetail?.Product
                                  ?.Provider.Name
                              }
                            </div>
                          </div>
                        </div>
                        <div className="order-details-summary-right">
                          <div className="order-history">
                            <div className="order-history-title">Publisher</div>
                            <div>
                              {
                                this.props.promotions.promotionDetail?.Product
                                  ?.Publisher.Name
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="order-history mt-2">
                          <div className="order-history-title">OS</div>
                          <div>
                            {this.props.promotions.promotionDetail?.OperatingSystems?.filter(
                              (f) => f
                            ).join(", ")}
                          </div>
                        </div>
                        <div className="order-history mt-2">
                          <div className="order-history-title">Platform</div>
                          <div>
                            {this.props.promotions.promotionDetail?.Platforms?.filter(
                              (f) => f
                            ).join(", ")}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="order-history mt-2">
                          <div className="order-history-title">
                            Quantity<sup>min</sup>
                          </div>
                          <div>
                            {
                              this.props.promotions.promotionDetail
                                ?.VoiduPromotion?.MinQuantity
                            }
                          </div>
                        </div>
                        <div className="order-history mt-2">
                          <div className="order-history-title">
                            Quantity<sup>max</sup>
                          </div>
                          <div>
                            {
                              this.props.promotions.promotionDetail
                                ?.VoiduPromotion?.MaxQuantity
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <div className="card promotion-detail-price-card py-4 px-5 d-flex flex-column justify-content-center">
                      <div className="edit-button">
                        <Button
                          className="detail-button px-3"
                          label="Edit"
                          bindEvent={() => this.editPromotion()}
                          buttonIcon={Icons.nullIcon}
                          iconWidth={20}
                          iconHeight={20}
                        />
                      </div>
                      <div className="order-history mt-2">
                        <div className="order-history-title">MSKU</div>
                        <div>
                          {this.props.promotions.promotionDetail?.Product?.MSku}
                        </div>
                      </div>
                      <div className="order-history mt-2">
                        <div className="order-history-title">SKU</div>
                        <div>
                          {this.props.promotions.promotionDetail?.Product?.Sku}
                        </div>
                      </div>
                      <div className="order-history mt-2">
                        <div className="order-history-title">Promotion Id</div>
                        <div>{this.props.promotions.promotionDetail?.Id}</div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="order-history mt-2">
                          <div className="order-history-title">
                            Merkur Start Date <sup>UTC</sup>
                          </div>
                          <div>
                            {moment(
                              fixTimezoneOffset(
                                this.props.promotions.promotionDetail
                                  ?.ValidFrom,
                                false
                              ).date
                            ).format(defaultDateFormat + "\tH:mm:ss")}{" "}
                          </div>
                        </div>
                        <div className="order-history mt-2 mx-2">
                          <div className="order-history-title">
                            Merkur End Date <sup>UTC</sup>
                          </div>
                          <div>
                            {moment(
                              fixTimezoneOffset(
                                this.props.promotions.promotionDetail?.ValidTo,
                                false
                              ).date
                            ).format(defaultDateFormat + "\tH:mm:ss")}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card promotion-detail-percentage-card">
                      <div className="py-4 px-5 d-flex flex-column">
                        <div className="order-history mt-0">
                          <div className="order-history-title">Regions</div>
                          <div>
                            <>
                              <p className="m-0">
                                <b>Countries</b>
                              </p>
                              {this.props.promotions.promotionDetail?.ProviderPromotions?.map(
                                (item) =>
                                  item.Countries.map(
                                    (countryItem) => countryItem
                                  ).join(", ")
                              )}
                            </>
                          </div>
                        </div>
                        <div className="order-history mt-2">
                          <div className="order-history-title">
                            Available Countries
                          </div>
                          <div>
                            <div className="order-detail-table">
                              <table className="table align-items-center mb-0">
                                <thead>
                                  <tr className="order-history">
                                    <th className="p-0 pt-1">
                                      <b>Countries</b>
                                    </th>
                                    <th className="p-0 pt-1">
                                      <b>Cost</b>
                                    </th>
                                    <th className="p-0 pt-1">
                                      <b>Sale Price</b>
                                    </th>
                                    <th className="p-0 pt-1">
                                      <b>%</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="order-history">
                                  {this.props.promotions.promotionDetail?.Countries.map(
                                    (item, index) => {
                                      return this.props.promotions.allcountries?.filter(
                                        (f) => f === item.Code
                                      ).length === 0 ? (
                                        <tr
                                          key={index}
                                          className="bg-danger text-white"
                                        >
                                          <td className="p-0">
                                            <p>{item.Code}</p>
                                          </td>
                                          <td className="p-0">
                                            <p></p>
                                          </td>
                                          <td className="p-0">
                                            <p></p>
                                          </td>
                                          <td className="p-0">
                                            <p>{item.Weight}</p>
                                          </td>
                                        </tr>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}

                                  {this.props.promotions.promotionDetail?.Countries.map(
                                    (item, index) => {
                                      return this.props.promotions.promotionDetail?.Product.Prices.map(
                                        (m) =>
                                          m.Countries.map((mm) =>
                                            mm === item.Code ? (
                                              <tr key={index}>
                                                <td className="p-0">
                                                  <p>{item.Code}</p>
                                                </td>
                                                <td className="p-0">
                                                  <p>{m.Cost}</p>
                                                </td>
                                                <td className="p-0">
                                                  <p>{m.Price}</p>
                                                </td>
                                                <td className="p-0">
                                                  <p>{item.Weight}</p>
                                                </td>
                                              </tr>
                                            ) : null
                                          )
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <Filter
                hideSearch
                filter={this.state.filter}
                onFilter={(ev) => this.onFilter(ev)}
                isPartnerAvailable
                priceList={[
                  { down: undefined, up: 25 },
                  { down: 25, up: 50 },
                  { down: 50, up: 100 },
                  { down: 100, up: 200 },
                  { down: 300, up: undefined },
                ]}
                dateList={[
                  dateTypes.ALLTIME,
                  dateTypes.LASTMONTH,
                  dateTypes.THISMONTH,
                ]}
                statusList={[
                  OrderStatus.Completed,
                  OrderStatus.Confirmed,
                  OrderStatus.Closed,
                  OrderStatus.Cancelled,
                  OrderStatus.Failed,
                  OrderStatus.Processing,
                  OrderStatus.Refused,
                  OrderStatus.Refund,
                  OrderStatus.Refunded,
                  OrderStatus.Rejected,
                  OrderStatus.Rebidding,
                  OrderStatus.Revoked,
                  OrderStatus.Submitted,
                  OrderStatus.Withdraw,
                ]}
                statusVisible
              />
            </div>
            <div className="col-12 col-sm-12 card p-5 mt-3">
              <div className="table-total-count">
                Total Count: {this.props.order?.orderList[0]?.TotalCount}
              </div>
              <div className="table-responsive-xl">
                <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
                  <thead>
                    <tr>
                      <th>
                        <p>Order Number</p>
                      </th>
                      <th>
                        <p>Name</p>
                      </th>
                      <th>
                        <p>Partner</p>
                      </th>
                      <th>
                        <p>Status</p>
                      </th>
                      <th>
                        <p>Order Quantity</p>
                      </th>
                      <th>
                        <p>Order Price</p>
                      </th>
                      <th>
                        <p>Total Price</p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.order.orderList[0]?.Items.length === 0 ? (
                      <label className="order-detail-table-no-item">
                        There is no order !
                      </label>
                    ) : (
                      this.props.order.orderList[0]?.Items.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p>{item.OrderNumber} </p>
                              </td>
                              <td>
                                <p>{item.Product.ProductName}</p>
                              </td>
                              <td>
                                <p>{item.Partner.Name}</p>
                              </td>
                              <td>
                                <p>{item.Status}</p>
                              </td>
                              <td>
                                <p>{item.Offer.OfferQuantity}</p>
                              </td>
                              <td>
                                <p>
                                  {" "}
                                  <NumberFormatter
                                    offerPrice={item.Offer.OfferPrice}
                                    supVisible
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <NumberFormatter
                                    offerPrice={
                                      item.Offer.OfferQuantity *
                                      item.Offer.OfferPrice
                                    }
                                    supVisible
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <Link
                                    to={Endpoints.Management.OrderDetail.url.replace(
                                      ":" +
                                        Endpoints.Management.OrderDetail.url.split(
                                          ":"
                                        )[1],
                                      item.Id
                                    )}
                                    className="order-history fw-bold"
                                  >
                                    View Detail
                                  </Link>
                                </p>
                              </td>
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Pager
              pageIndex={this.props.order.orderList[0]?.PageIndex}
              totalPages={this.props.order.orderList[0]?.TotalPages}
              totalCount={this.props.order.orderList[0]?.TotalCount}
              onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
            />
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetail);
