export default (req) => ({
  getOrderItems: (pageIndex, pageSize, sortBy, state) =>
    req.get("/report/management/GetItems", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SortBy: sortBy,
        State: state,
      },
    }),
  getOrderStatus: (pageIndex, pageSize, startDate, endDate) =>
    req.get("/report/management/orderstatus", {
      params: {
        StartDate: startDate,
        EndDate: endDate,
      },
    }),
  getOrderItemsCountByState: (pageIndex, pageSize, state) =>
    req.get("/report/management/OrderItemCountByState", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
        State: state,
      },
    }),
  getOrderStatusPartner: (startDate, endDate) =>
    req.get("/report/me/orderstatus", {
      params: {
        StartDate: startDate,
        EndDate: endDate,
      },
    }),
  getTopSellers: (startDate, endDate, pageIndex, pageSize) =>
    req.get("/report/management/TopSellers", {
      params: {
        StartDate: startDate,
        EndDate: endDate,
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  // GetInventoryReports: (endpoint) =>
  //   req.get(`/report/${endpoint}/customreports`),
  // GetInventoryReport: (title, endpoint) =>
  //   req.get(`/report/${endpoint}/customreport/${title}`),
  GetInventoryReports: (Keywords, PageIndex, PageSize, role = "management") =>
    req.get(
      `/report/${role}/InventoryReports?Keywords=${Keywords}&PageIndex=${PageIndex}&PageSize=${PageSize}`
    ),
  GetInventoryReportDetails: (
    reportName,
    Keywords,
    PageIndex,
    PageSize,
    role = "management"
  ) =>
    req.get(
      `/report/${role}/InventoryReportDetail/${reportName}?Keywords=${Keywords}&PageIndex=${PageIndex}&PageSize=${PageSize}`
    ),

  GetStatusCountByProductSku: (ProductSku, StartDate, EndDate) =>
    req.get("/report/management/GetProductReport", {
      params: {
        ProductSku: ProductSku,
        StartDate: StartDate,
        EndDate: EndDate,
      },
    }),
});
