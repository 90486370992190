/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { connect } from "react-redux";
import NavItem from "./NavItem";
import { MENU_STATUS_CHANGE, REDIRECT } from "../constants/actionTypes";
import { salesman, partner, superadmin, finance } from "../constants/defaults";
import { Endpoints } from "../constants/endpoints";
import { Icons } from "../constants/icons";
import NavMenu from "./NavMenu";
import { StaticPermission } from "../constants/permission";

const mapStateToProps = (state) => ({
  menuCollapse: state.common.menuCollapse,
  currentUser: state.auth.currentUser,
  inventoryReports: state.report.inventoryReports,
});

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (key) => {
    dispatch({ type: REDIRECT, redirectTo: key });
  },
  menuCollapseChange: (menuCollapse) =>
    dispatch({ type: MENU_STATUS_CHANGE, menuCollapse }),
});

class Sidebar extends React.Component {
  render() {
    const management = [superadmin, salesman];
    const managementFinance = [superadmin, salesman, finance];
    const onlyPartner = [partner];
    const onlyFinance = [finance];

    return (
      <div>
        <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
          <a className="navbar-brand me-lg-5" href="/">
            <img
              className="navbar-brand-dark"
              src={Icons.merkurLogoIcon}
              alt="Merkur Logo"
            />
          </a>
          <div className="d-flex align-items-center">
            <button
              className="navbar-toggler d-md-none collapsed"
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.props.menuCollapseChange(false)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <nav
          id="sidebarMenu"
          className={
            "sidebar d-md-block " +
            (this.props.menuCollapse ? " collapse" : " show")
          }
          data-simplebar="init"
        >
          <div className="simplebar-wrapper">
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div className="simplebar-offset">
                <div
                  className="simplebar-content-wrapper"
                  style={{ height: "auto" }}
                >
                  <div className="simplebar-content">
                    <div className="sidebar-inner">
                      <div className="p-4">
                        <div className="d-flex align-items-center justify-content-between d-md-none">
                          <a className="navbar-brand" href="/">
                            <img
                              className="navbar-brand-dark"
                              src={Icons.merkurLogoIcon}
                              alt="Merkur Logo"
                            />
                          </a>
                          <div className="collapse-close ">
                            <a
                              href="#sidebarMenu"
                              className="fas fa-times"
                              aria-expanded="true"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                this.props.menuCollapseChange(true)
                              }
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 d-none d-md-block mx-auto mt-5 pb-5">
                        <a href="/">
                          <img
                            alt="logo"
                            src={Icons.merkurLogoIcon}
                            className="w-100"
                          />
                        </a>
                      </div>
                      <ul className="nav flex-column pt-3 pt-md-0">
                        <NavItem
                          url={Endpoints.Management.Home.url}
                          text={Endpoints.Management.Home.subMenuText}
                          accessScope={management}
                          icon="home"
                        ></NavItem>
                        <NavMenu
                          MenuId="Promotions"
                          text="Promotions"
                          accessScope={management}
                          icon={Icons.iconPromotions}
                        >
                          <NavItem
                            url={Endpoints.Management.Promotions.url
                              .replace(
                                ":" +
                                  Endpoints.Management.Promotions.url.split(":")
                              )
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={Endpoints.Management.Promotions.subMenuText}
                            accessScope={management}
                            icon="promotions"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.UpcomingPromotions.url}
                            text={
                              Endpoints.Management.UpcomingPromotions
                                .subMenuText
                            }
                            accessScope={management}
                            icon="upcoming-promotions"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.ProviderProducts.url
                              .replace(
                                ":" +
                                  Endpoints.Management.ProviderProducts.url.split(
                                    ":"
                                  )
                              )
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={
                              Endpoints.Management.ProviderProducts.subMenuText
                            }
                            accessScope={management}
                            icon="provider-products"
                          ></NavItem>
                        </NavMenu>
                        <NavItem
                          url={Endpoints.Management.Orders.url
                            .replace("/:tabName?", "")
                            .replace(":filter?", "")
                            .replace("/:sort?", "")
                            .replace("/:page?", "")}
                          text={Endpoints.Management.Orders.subMenuText}
                          accessScope={management}
                          icon="orders"
                        ></NavItem>
                        <NavMenu
                          MenuId="Finance"
                          text="Finance"
                          accessScope={management}
                          icon={Icons.iconPromotions}
                        >
                          <NavItem
                            url={Endpoints.Management.Invoices.url
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={Endpoints.Management.Invoices.subMenuText}
                            accessScope={management}
                            icon="invoice"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.UnInvoicedOrders.url
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={
                              Endpoints.Management.UnInvoicedOrders.subMenuText
                            }
                            accessScope={management}
                            icon="invoice"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.CreditNotes.url
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={Endpoints.Management.CreditNotes.subMenuText}
                            accessScope={management}
                            icon="creditnote"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.Vendors.url}
                            text={Endpoints.Management.Vendors.subMenuText}
                            accessScope={management}
                            icon="inventories"
                          ></NavItem>
                        </NavMenu>
                        <NavMenu
                          MenuId="Inventories"
                          text="Inventories"
                          accessScope={management}
                          icon={Icons.iconInventories}
                        >
                          <NavItem
                            url={Endpoints.Management.Inventories.url}
                            text={Endpoints.Management.Inventories.subMenuText}
                            accessScope={management}
                            icon="inventories"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.ManuelStock.url}
                            text={Endpoints.Management.ManuelStock.subMenuText}
                            accessScope={management}
                            icon="inventory-report"
                          ></NavItem>
                        </NavMenu>

                        {/* <NavItem
                                  url={Endpoints.Management.Allocations.url}
                                  text={
                                    Endpoints.Management.Allocations.subMenuText
                                  }
                                  
                                  accessScope={management}
                                  icon="allocations"
                                ></NavItem> */}
                        <NavMenu
                          MenuId="Companies"
                          text="Companies"
                          accessScope={management}
                          icon={Icons.iconCompanies}
                        >
                          <NavItem
                            url={Endpoints.Management.Companies.url}
                            text={Endpoints.Management.Companies.subMenuText}
                            accessScope={management}
                            icon="companies"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.Users.url.replace(
                              "/:userid?",
                              ""
                            )}
                            text={Endpoints.Management.Users.subMenuText}
                            accessScope={management}
                            icon="users"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.PermissionGroup.url}
                            text={
                              Endpoints.Management.PermissionGroup.subMenuText
                            }
                            accessScope={management}
                            icon="permission"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.CreditLimits.url}
                            text={Endpoints.Management.CreditLimits.subMenuText}
                            accessScope={management}
                            icon="credit-limit"
                          ></NavItem>
                        </NavMenu>
                        <NavMenu
                          MenuId="MyPromotions"
                          text="My Promotions"
                          accessScope={onlyPartner}
                          icon={Icons.iconPromotions}
                        >
                          <NavItem
                            url={Endpoints.Partner.AllPromotions.url
                              .replace(
                                ":" +
                                  Endpoints.Partner.AllPromotions.url.split(":")
                              )
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={Endpoints.Partner.AllPromotions.subMenuText}
                            accessScope={onlyPartner}
                            icon="promotions"
                            permission={StaticPermission.Promotion.View}
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Partner.BulkPromotion.url}
                            text={Endpoints.Partner.BulkPromotion.subMenuText}
                            accessScope={onlyPartner}
                            icon="active-promotions"
                            permission={StaticPermission.Promotion.View}
                          ></NavItem>
                          <NavItem
                            url={
                              Endpoints.Partner.UpcomingPromotionsPartner.url
                            }
                            text={
                              Endpoints.Partner.UpcomingPromotionsPartner
                                .subMenuText
                            }
                            accessScope={onlyPartner}
                            icon="upcoming-promotions"
                            permission={StaticPermission.Promotion.UpcomingView}
                          ></NavItem>
                        </NavMenu>
                        <NavMenu
                          MenuId="Report"
                          text="Report"
                          accessScope={managementFinance}
                          icon={Icons.iconReport}
                        >
                          <NavItem
                            url={Endpoints.Management.TopSellers.url
                              .replace(
                                ":" +
                                  Endpoints.Management.TopSellers.url.split(":")
                              )
                              .replace("/:filter?", "")
                              .replace("/:sort?", "")
                              .replace("/:page?", "")}
                            text={Endpoints.Management.TopSellers.subMenuText}
                            accessScope={management}
                            icon="top-sellers"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.ProductReport.url
                              .replace(
                                ":" +
                                  Endpoints.Management.TopSellers.url.split(":")
                              )
                              .replace("/:filter?", "")}
                            text={
                              Endpoints.Management.ProductReport.subMenuText
                            }
                            accessScope={management}
                            icon="product-report"
                          ></NavItem>
                          <NavItem
                            url={
                              Endpoints.Management.InventoryReportsManagement
                                .url
                            }
                            text="Inventory Reports"
                            accessScope={management}
                            icon="inventory-report"
                          ></NavItem>
                          <NavItem
                            url={Endpoints.Management.InventoryReports.url}
                            text="Inventory Reports"
                            accessScope={finance}
                            icon="inventory-report"
                          ></NavItem>
                        </NavMenu>
                        <NavItem
                          url={Endpoints.Management.Settings.url}
                          text={Endpoints.Management.Settings.subMenuText}
                          accessScope={superadmin}
                          icon="settings"
                          // permission={StaticPermission.Promotion.Settings}
                        ></NavItem>
                        <NavItem
                          url={Endpoints.Partner.DirectSales.url
                            .replace(":filter?", "")
                            .replace("/:sort?", "")
                            .replace("/:page?", "")}
                          text={Endpoints.Partner.DirectSales.subMenuText}
                          accessScope={onlyPartner}
                          icon="direct-sales"
                          new
                          permission={
                            StaticPermission.Promotion.DirectSalesView
                          }
                        ></NavItem>

                        <NavItem
                          url={Endpoints.Partner.MyOrders.url
                            .replace(
                              ":" +
                                Endpoints.Partner.MyOrders.url.split(":")[1],
                              "send"
                            )
                            .replace(":filter?", "")
                            .replace("/:sort?", "")
                            .replace("/:page?", "")}
                          text={Endpoints.Partner.MyOrders.subMenuText}
                          accessScope={onlyPartner}
                          icon="orders"
                          permission={StaticPermission.Order.View}
                        ></NavItem>
                        <NavItem
                          url={Endpoints.Partner.MyInvoices.url
                            .replace(
                              ":" + Endpoints.Partner.MyInvoices.url.split(":")
                            )
                            .replace("/:filter?", "")
                            .replace("/:sort?", "")
                            .replace("/:page?", "")}
                          text={Endpoints.Partner.MyInvoices.subMenuText}
                          accessScope={onlyPartner}
                          icon="invoice"
                          permission={StaticPermission.Invoice.View}
                        ></NavItem>
                        <NavItem
                          url="/credit-limit"
                          text="My Credit Limit"
                          accessScope={onlyPartner}
                          icon="credit-limit"
                        ></NavItem>
                        <NavItem
                          url="/myaccount"
                          text="My Account"
                          accessScope={onlyPartner}
                          icon="users"
                        ></NavItem>
                        <NavItem
                          url="/myaccount"
                          text="My Account"
                          accessScope={onlyFinance}
                          icon="users"
                        ></NavItem>

                        {/* <NavItem
                                url="/messages"
                                text="Messages"
                                
                                accessScope={management}
                                icon="messages"
                              ></NavItem> */}
                        <li className="nav-item"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="simplebar-placeholder"
              style={{ width: "260px", height: "522px" }}
            ></div>
          </div>
          <div className="simplebar-track" style={{ visibility: "hidden" }}>
            <div
              className="simplebar-scrollbar"
              style={{ width: "0px", display: "none" }}
            ></div>
          </div>
          <div
            className="simplebar-track simplebar-vertical"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{ height: "0px", display: "none" }}
            ></div>
          </div>
        </nav>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
