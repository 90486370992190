import React from "react";
import { connect } from "react-redux";
import {
  COMPANIES_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import Loader from "../../../components/Loader";
import Filter from "../../../components/Filter";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import Button from "../../../components/common/Button";
import BlankPage from "../../../components/BlankPage";
import MerModal from "../../../components/common/MerModal";
import CreateorUpdateCompanyModal from "./components/CreateorUpdateCompanyModal";
import Pager from "../../../components/Pager";
import { Icons } from "../../../constants/icons";
import services from "../../../api/index";
import { Endpoints } from "../../../constants/endpoints";

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    redirectTo: state.common.redirectTo,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateorUpdateCompanyModal: false,
      selectedCompany: undefined,
    };
  }

  componentDidMount() {
    document.title = "Companies";
    this.getCompanies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  modalClose = () => {
    this.setState({ showCreateorUpdateCompanyModal: false });
  };

  getCompanies = (params) => {
    const {
      keywords = "",
      pageindex = 0,
      pagesize = this.props.pageSize,
    } = params || {};
    this.props.companiesOnLoad(
      Promise.all([
        services.company.getCompanies(keywords, pageindex, pagesize),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  onChangePageIndex(pageindex) {
    this.onFilter({
      pageindex: pageindex,
      filter: this.state.filter,
    });
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;

    if (sortBy !== undefined) {
      if (sortBy === this.state.sortBy) sortBy = `-${sortBy}`;
      this.setState({ sortBy });
    } else sortBy = this.state.sortBy;
    this.getCompanies({
      keywords: ev?.Text,
      pageindex: pageindex,
    });
  }

  render() {
    const { companyList } = this.props.companies;
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Companies</h1>
          <Button
            className="create_promotion_button mx-3 px-4"
            label="Create Company"
            bindEvent={() => {
              this.setState({
                showCreateorUpdateCompanyModal: true,
                selectedCompany: undefined,
              });
            }}
            buttonIcon={Icons.nullIcon}
            iconWidth={17}
            iconHeight={17}
          />
        </div>
        {companyList === undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev) => this.onFilter(ev)}
            ></Filter>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : companyList[0]?.length === 0 ? (
              <BlankPage description="There is no promotion." />
            ) : (
              <div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer table-width-100"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">
                          Company Name
                        </th>
                        <th className="bg-transparent border-0 ">
                          Company Email
                        </th>
                        <th className="bg-transparent border-0 ">Country</th>
                        <th className="bg-transparent border-0 ">Address</th>
                        <th className="bg-transparent border-0 ">
                          Total Credit Limit
                        </th>
                        <th className="bg-transparent border-0 ">
                          Package Limit
                        </th>
                        <th className="bg-transparent border-0 ">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList[0].Items.map((item, key) => (
                        <tr className="rounded-1 mb-1 align-middle border-bottom ">
                          <td>{item.Name}</td>
                          <td>{item.Email}</td>
                          <td>{item.Country}</td>
                          <td>{item.Address}</td>
                          <td>
                            <div>
                              <label>Total Credit Limit:</label>{" "}
                              {item.AccountingInfo.TotalCreditLimit}
                            </div>
                            <div>
                              <label>PackageLimit:</label>{" "}
                              {item.AccountingInfo.PackageLimit}
                            </div>
                            <div>
                              {" "}
                              <label>Balance:</label>{" "}
                              {item.AccountingInfo.Balance}
                            </div>
                          </td>

                          <td>
                            <Button
                              className="detail-button px-3"
                              label="Edit"
                              bindEvent={() => {
                                this.setState({
                                  showCreateorUpdateCompanyModal: true,
                                  selectedCompany: item,
                                });
                              }}
                              buttonIcon={Icons.nullIcon}
                              iconWidth={20}
                              iconHeight={20}
                            />
                          </td>
                          <td>
                            <Button
                              className="account-module-button px-3"
                              label=""
                              bindEvent={() => {
                                this.props.onRedirect(
                                  Endpoints.Management.CompanyAccount.url.replace(
                                    ":partnerid",
                                    item.Id
                                  )
                                );
                              }}
                              buttonIcon={Icons.nullIcon}
                              iconWidth={20}
                              iconHeight={20}
                            />{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Pager
                  pageIndex={companyList[0]?.PageIndex}
                  totalPages={companyList[0]?.TotalPages}
                  totalCount={companyList[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
            <MerModal
              id="create-company"
              showModal={this.state.showCreateorUpdateCompanyModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.modalClose()}
            >
              <CreateorUpdateCompanyModal
                handleClose={() => this.modalClose()}
                selectedCompany={this.state.selectedCompany}
                companiesRefresh={() => this.getCompanies()}
              ></CreateorUpdateCompanyModal>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
