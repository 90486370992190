import {
  REPORT_ORDER_ITEMS,
  REPORT_ORDER_ITEMS_COUNT_BY_STATE,
  REPORT_TOP_SELLERS_PAGE_LOADED,
  REPORT_PRODUCT_REPORT_PAGE_LOADED,
  INVENTORY_REPORTS_LOADED,
  INVENTORY_REPORT_LOADED,
} from "../constants/actionTypes";

const defaultState = {
  reportOrderItems: undefined,
  reportOrderStatus: undefined,
  reportOrderItemsCountByState: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REPORT_ORDER_ITEMS:
      return {
        ...state,
        reportOrderItems: action.payload,
      };

    case REPORT_ORDER_ITEMS_COUNT_BY_STATE:
      return {
        ...state,
        reportOrderItemsCountByState: action.payload,
      };
    case REPORT_TOP_SELLERS_PAGE_LOADED:
      return {
        ...state,
        reportTopSellers: action.payload,
      };
    case REPORT_PRODUCT_REPORT_PAGE_LOADED:
      return {
        ...state,
        reportProductResult: action.payload,
      };
    case INVENTORY_REPORTS_LOADED:
      return {
        ...state,
        inventoryReports: action.payload,
      };
    case INVENTORY_REPORT_LOADED:
      return {
        ...state,
        inventoryReport: action.payload,
      };
  }
  return state;
};
