import React from "react";
import { connect } from "react-redux";
import {
  GET_ORDER,
  GET_ORDER_ITEMS,
  GET_ORDER_BLENDING,
} from "../../../constants/actionTypes";
import {
  OrderItemStatus,
  OrderStatus,
  partner,
} from "../../../constants/defaults";
import Pager from "../../../components/Pager";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import Filter from "../../../components/Filter";
import Button from "../../../components/common/Button";
import NumberFormatter from "../../../components/common/NumberFormatter";
import MerModal from "../../../components/common/MerModal";
import ManagementOrderItemDetail from "./components/modal/ManagementOrderItemDetail";
import Content from "../../../components/containers/Content";
import { Icons } from "../../../constants/icons";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    order: state.order,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  orderOnLoad: (payload) => dispatch({ type: GET_ORDER, payload }),
  orderItemsOnLoad: (payload) => dispatch({ type: GET_ORDER_ITEMS, payload }),
  blendingOnLoad: (payload) => dispatch({ type: GET_ORDER_BLENDING, payload }),
});

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderid: this.props.match.params.orderid,
      pageindex: 0,
      tableIsLoading: false,
      showDetail: false,
      orderItemMessage: "",
      orderItemStatus: "",
      statusList: "",
      collapsed: true,
    };
  }

  componentDidMount() {
    this.getorder();
    this.props.orderItemsOnLoad(this.getOrderItems());
  }

  getorder() {
    this.props.orderOnLoad(
      Promise.all([
        services.orders
          .getManagementOrder(this.state.orderid)
          .then((res) => {
            document.title = res.Product?.ProductName + " - Order Details";
            return res;
          })
          .catch((err) => {
            if (err.response?.data?.message)
              toast.error(<div>{err.response?.data.message}</div>);
            return undefined;
          }),
      ])
    );

    this.props.blendingOnLoad(
      Promise.all([
        services.orders
          .getOrderBlending(this.state.orderid)
          .then((res) => {
            return res;
          })
          .catch((err) => {
            if (err.response?.data?.message)
              toast.error(<div>{err.response?.data.message}</div>);
            return undefined;
          }),
      ])
    );
  }

  getOrderItems(params) {
    const {
      orderid = this.state.orderid,
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      states,
    } = params || {};
    this.setState({ pageindex: pageindex, statusList: states });

    return this.props.orderItemsOnLoad(
      Promise.all([
        services.orders.getOrderItems(orderid, pageindex, pagesize, states),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    if (sortBy !== undefined) {
      if (sortBy === this.state.sortBy) sortBy = `-${sortBy}`;
      this.setState({ sortBy });
    } else sortBy = this.state.sortBy;

    this.getOrderItems({
      orderid: this.state.orderid,
      pageindex: pageindex,
      states: ev?.Status.length !== 0 ? ev?.Status.join(",") : "",
    });
  }

  deleteOrderItem(orderItemId) {
    services.orders
      .deleteManagementOrderItem(this.state.orderid, orderItemId)
      .then((res) => {
        toast.success(<div>Orderitem has been deleted successfully!</div>);
        this.onFilter({ states: "Error" });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  deleteErrorOrderItem() {
    services.orders
      .deleteManagementErrorOrderItem(this.state.orderid)
      .then((res) => {
        toast.success(<div>Orderitems has been deleted successfully!</div>);
        this.onFilter({ states: "Error" });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  retryOrderItem(orderItemId) {
    services.orders
      .retryManagementOrderItem(this.state.orderid, orderItemId)
      .then((res) => {
        toast.success(<div>Orderitem has been retried successfully!</div>);
        this.onFilter({ states: "OutOfStock" });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  retryAllOrderItem() {
    this.setState({ tableIsLoading: true });
    services.orders
      .retryAllManagementOrderItem(this.state.orderid)
      .then((res) => {
        toast.success(<div>All orderitems has been retried successfully!</div>);
        this.onFilter({ states: "OutOfStock" });
        this.setState({ tableIsLoading: false });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  refundBackOrderedItems() {
    this.setState({ tableIsLoading: true });
    services.orders
      .refundBackOrderedItems(this.state.orderid)
      .then((res) => {
        toast.success(
          <div>All orderitems has been refunded successfully!</div>
        );
        this.onFilter({ states: "OutOfStock" });
        this.setState({ tableIsLoading: false });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  detailModalClose = () => {
    this.setState({ showDetail: false });
  };

  detailModalOpen = (id, status, message) => {
    this.setState({
      showDetail: true,
      orderItemId: id,
      orderItemStatus: status,
      orderItemMessage: message,
    });
  };

  render() {
    const { order, orderItems } = this.props.order;
    return (
      <Content>
        {order === undefined || orderItems === undefined ? (
          <Loader />
        ) : (
          <div>
            <h1>{order[0]?.Product?.ProductName}</h1>
            <div className="order-detail d-flex justify-content-between">
              <div className="card order-details-summary justify-content-start">
                <div className="order-container d-flex flex-row">
                  <div className="order-no">{order[0]?.OrderNumber}</div>
                  <div className="order-history mt-0 text-end">
                    <div className="order-history-title">
                      Est. Finish Time <sup>UTC</sup>
                    </div>
                    {order[0]?.EstimatedFinishDate ? (
                      <div>
                        {
                          (moment.locale("en"),
                          moment
                            .utc(order[0]?.EstimatedFinishDate)
                            .format("DD MMMM yyyy, HH:mm:ss"))
                        }
                      </div>
                    ) : (
                      <div className="mb-3 mx-4">-</div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row">
                  <div className="order-details-summary-left">
                    <div className="d-flex flex-row justify-content-between flex-lg-column">
                      <div className="order-history mt-2 me-3">
                        <div className="order-history-title">Partner</div>
                        <div>{order[0]?.Partner.Name}</div>
                      </div>
                      <div className="order-history mt-2 me-3">
                        <div className="order-history-title">
                          Created Date<sup>UTC</sup>
                        </div>
                        <div>
                          {moment
                            .utc(order[0]?.CreatedAt)
                            .format("DD.MM.yyyy HH:mm:ss")}
                        </div>
                      </div>
                      <div className="order-history mt-2 me-3">
                        <div className="order-history-title">
                          Release Date<sup>UTC</sup>
                        </div>
                        <div>
                          {moment
                            .utc(order[0]?.Product.ProductReleaseDate)
                            .format("DD.MM.yyyy HH:mm:ss")}
                        </div>
                      </div>
                    </div>
                    <div className="order-history mt-2 mb-2">
                      <div className="order-history-title">Product SKU</div>
                      <div>{order[0]?.Product.ProductSku}</div>
                    </div>
                  </div>
                  <div className="order-history m-0 mt-2">
                    <div className="order-history-title">Order History</div>
                    <div className="order-history-subtitle">
                      DP: Demanded Price OP: Offered Price
                    </div>
                    <div className="order-history-body">
                      {order[0]?.History.slice()
                        .reverse()
                        .map((val, index) => (
                          <div className="history-item-horizontal" key={index}>
                            <div className="history-item-vertical">
                              <i className="far fa-circle" />
                              <div className="history-item">
                                {
                                  (moment.locale("en"),
                                  moment
                                    .utc(val.ModifiedAt)
                                    .format("DD MMMM yyyy, hh:mm:ssA, ") +
                                    val.OrderStatus +
                                    " from " +
                                    (val.ModifiedByType === "Salesman"
                                      ? val.ModifiedByName
                                      : val.ModifiedByType) +
                                    ", ")
                                }
                                {" " +
                                  (val.ModifiedByType === partner
                                    ? "DP "
                                    : "OP ") +
                                  val.OfferPrice +
                                  "€ x " +
                                  val.OfferQuantity +
                                  " =  " +
                                  Math.round(
                                    val.OfferPrice * val.OfferQuantity * 100
                                  ) /
                                    100 +
                                  "€"}
                              </div>
                            </div>
                            {order[0]?.History.length - 1 > index ? (
                              <i className="fas fa-ellipsis-v" />
                            ) : null}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card order-details-quantity d-flex flex-column justify-content-center py-4">
                <div className="d-flex fs-6 mx-5 justify-content-between order-history">
                  <div className="mt-3 order-history-title">Order Status</div>
                  <div
                    className={`order-history-quantity-status ${
                      order[0]?.Status === OrderStatus.Processing ||
                      order[0]?.Status === OrderStatus.Confirmed ||
                      order[0]?.Status === OrderStatus.Completed ||
                      order[0]?.Status === OrderStatus.Refunded ||
                      order[0]?.Status === OrderStatus.Refund
                        ? "yellowish-green"
                        : order[0]?.Status === OrderStatus.Rebidding ||
                          order[0]?.Status === OrderStatus.Revoked ||
                          order[0]?.Status === OrderStatus.Withdraw
                        ? "mango"
                        : order[0]?.Status === OrderStatus.Refused ||
                          order[0]?.Status === OrderStatus.Rejected ||
                          order[0]?.Status === OrderStatus.Failded
                        ? "carnation"
                        : order[0]?.Status === OrderStatus.Submitted ||
                          order[0]?.Status === OrderStatus.Closed
                        ? "dark-slate-blue"
                        : "cloudy-blue"
                    }`}
                  >
                    {order[0]?.Status}
                  </div>
                </div>

                <div className="d-flex fs-6 mx-5 justify-content-between">
                  <div className="order-history">Offer Quantity</div>
                  <div className="order-history">
                    {order[0]?.Offer.OfferQuantity}
                  </div>
                </div>
                <div className="d-flex fs-6 mx-5 justify-content-between">
                  <div className="order-history">Available Quantity</div>
                  <div className="order-history">
                    {order[0]?.CompletedQuantity - order[0]?.DeliveredQuantity}
                  </div>
                </div>
                <div className="d-flex fs-6 mx-5 justify-content-between">
                  <div className="order-history">Remaining Quantity</div>
                  <div className="order-history">
                    {order[0]?.Offer.OfferQuantity -
                      order[0]?.CompletedQuantity}
                  </div>
                </div>
                <div className="d-flex fs-6 mx-5 justify-content-between dashed-line">
                  <div className="order-history">Delivered Quantity</div>
                  <div className="order-history">
                    {order[0]?.DeliveredQuantity}
                  </div>
                </div>
                <div className="d-flex fs-6 mx-5 justify-content-between">
                  <div className="order-history">Estimated Cost</div>
                  <div className="order-history">
                    <NumberFormatter
                      offerPrice={
                        order[0]?.Product.VoiduPromotion.EstimatedCost
                      }
                      supVisible
                    />
                  </div>
                </div>
                {order[0]?.Product.VoiduPromotion.EstimatedCost > 0 && (
                  <div className="d-flex fs-6 mx-5 justify-content-between">
                    <span className="order-history">Estimated Profit</span>
                    <div className="d-flex flex-row justify-content-end">
                      <div className="order-history">
                        {(
                          ((order[0]?.Offer.OfferPrice -
                            order[0]?.Product.VoiduPromotion.EstimatedCost) *
                            100) /
                          order[0]?.Offer.OfferPrice
                        ).toFixed(2) + "%"}
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex fs-6 mx-5 justify-content-between">
                  <div className="order-history">Actual Cost</div>
                  <div className="d-flex flex-row justify-content-end">
                    <div className="order-history">
                      <NumberFormatter
                        offerPrice={order[0]?.Product.VoiduPromotion.ActualCost}
                        supVisible
                      />
                    </div>
                  </div>
                </div>
                {order[0]?.Product.VoiduPromotion.ActualCost > 0 && (
                  <div className="d-flex fs-6 mx-5 justify-content-between">
                    <span className="order-history">Actual Profit</span>
                    <div className="d-flex flex-row justify-content-end">
                      <div className="order-history">
                        {(
                          ((order[0]?.Offer.OfferPrice -
                            order[0]?.Product.VoiduPromotion.ActualCost) *
                            100) /
                          order[0]?.Offer.OfferPrice
                        ).toFixed(2) + "%"}
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex fs-6 mx-5 justify-content-between order-history">
                  <div className="mt-2 order-history-title">
                    Offer Unit Price
                  </div>
                  <div className="d-flex flex-row justify-content-end">
                    <span className="order-history-voidu-price-text">
                      List Price:{" "}
                    </span>
                    <div
                      className={`order-history-voidu-price ${
                        order[0]?.Product.VoiduPromotion.Price !==
                        order[0]?.Offer.OfferPrice
                          ? "strikediag"
                          : ""
                      }`}
                    >
                      <NumberFormatter
                        offerPrice={order[0]?.Product.VoiduPromotion.Price}
                        supVisible
                      />
                    </div>
                    <div
                      className={`order-detail-up-down-arrow ${
                        order[0]?.Product.VoiduPromotion.Price >
                        order[0]?.Offer.OfferPrice
                          ? "down-text-color"
                          : order[0]?.Product.VoiduPromotion.Price ===
                            order[0]?.Offer.OfferPrice
                          ? "equal-text-color"
                          : "up-text-color"
                      }`}
                    >
                      {order[0]?.Product.VoiduPromotion.Price >
                      order[0]?.Offer.OfferPrice ? (
                        <img src={Icons.arrowDownRedIcon} />
                      ) : order[0]?.Product.VoiduPromotion.Price ===
                        order[0]?.Offer.OfferPrice ? (
                        <img src={Icons.equalGrayIcon} />
                      ) : (
                        <img src={Icons.arrowUpGreenIcon} />
                      )}
                    </div>
                    <div
                      className={`order-history-quantity-price ${
                        order[0]?.Product.VoiduPromotion.Price >
                        order[0]?.Offer.OfferPrice
                          ? "down-text-color"
                          : order[0]?.Product.VoiduPromotion.Price ===
                            order[0]?.Offer.OfferPrice
                          ? "equal-text-color"
                          : "up-text-color"
                      }`}
                    >
                      <NumberFormatter
                        offerPrice={order[0]?.Offer.OfferPrice}
                        supVisible
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card  d-flex flex-column justify-content-center py-4">
              <div
                className="p-2 px-5 d-flex justify-content-between"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={() =>
                  this.setState({ collapsed: !this.state.collapsed })
                }
              >
                <div className="order-detail-title ">Order Blending</div>
                <div className="my-auto d-flex flex-column align-items-end">
                  <a>
                    <div>
                      <img
                        src={
                          !this.state.collapsed
                            ? Icons.collapseArrowUpIcon
                            : Icons.collapseArrowDownIcon
                        }
                      ></img>
                    </div>
                  </a>
                </div>
              </div>

              <div className="collapse" id="collapseExample">
                <div className="">
                  {this.props.order.blending !== undefined && (
                    <div className="table-responsive-xl">
                      <table
                        className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                        id="datatable"
                      >
                        <thead className="thead-light d-short border-0">
                          <tr>
                            <th className="bg-transparent border-0 ">
                              Country Code
                            </th>
                            <th className="bg-transparent border-0 ">
                              Estimate Percentage
                            </th>
                            <th className="bg-transparent border-0 ">
                              Actual Percentage
                            </th>

                            <th className="bg-transparent border-0 ">
                              Estimated Cost
                            </th>
                            <th className="bg-transparent border-0 ">
                              Actual Cost
                            </th>
                            <th className="bg-transparent border-0 ">
                              Actual Count
                            </th>
                            <th className="bg-transparent border-0 ">
                              Actual Total Cost
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.order.blending[0].map((item) => {
                            return (
                              <tr>
                                <td>{item.CountryCode}</td>
                                <td className="border-start">
                                  {item.EstimatePercentage}%
                                </td>
                                <td
                                  className={
                                    "border-end " +
                                    (item.EstimatePercentage ===
                                    item.ActualPercentage
                                      ? "text-success fw-bold"
                                      : "text-danger")
                                  }
                                >
                                  {item.ActualPercentage}%
                                </td>
                                <td>
                                  {order[0]?.Product.VoiduPromotion.EstimatedCost.toFixed(
                                    2
                                  ) + "€"}
                                </td>
                                <td
                                  className={
                                    "border-end " +
                                    (item.ActualCount === 0
                                      ? ""
                                      : order[0]?.Product.VoiduPromotion
                                          .EstimatedCost <
                                        item.ActualTotalCost / item.ActualCount
                                      ? "text-danger"
                                      : "text-success fw-bold")
                                  }
                                >
                                  {item.ActualCount > 0
                                    ? (
                                        item.ActualTotalCost / item.ActualCount
                                      ).toFixed(2) + "€"
                                    : "0"}
                                </td>

                                <td>{item.ActualCount}</td>

                                <td>{item.ActualTotalCost}€</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td className="bg-transparent border-0 "></td>
                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Est. Percentage Total
                            </td>
                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Act. Percentage Total
                            </td>

                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Avg. Estimated Cost
                            </td>
                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Avg. Actual Cost
                            </td>
                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Act. Total Count
                            </td>
                            <td className="bg-transparent border-bottom ps-0 fw-bold">
                              Act. Total Cost
                            </td>
                          </tr>

                          <tr>
                            <td></td>
                            <td>
                              {this.props.order.blending[0]
                                .map((item) => item.EstimatePercentage)
                                .reduce((prev, next) => prev + next)}
                              %
                            </td>
                            <td>
                              {this.props.order.blending[0]
                                .map((item) => item.ActualPercentage)
                                .reduce((prev, next) => prev + next)}
                              %
                            </td>
                            <td>
                              {order[0]?.Product.VoiduPromotion.EstimatedCost.toFixed(
                                2
                              ) + "€"}
                            </td>
                            <td>
                              {(
                                this.props.order.blending[0]
                                  .map((item) =>
                                    item.ActualCount > 0
                                      ? item.ActualTotalCost / item.ActualCount
                                      : 0
                                  )
                                  .reduce((prev, next) => prev + next) /
                                this.props.order.blending[0].length
                              ).toFixed(2)}
                              €
                            </td>
                            <td>
                              {this.props.order.blending[0]
                                .map((item) => item.ActualCount)
                                .reduce((prev, next) => prev + next)}
                            </td>
                            <td>
                              {this.props.order.blending[0]
                                .map((item) => item.ActualTotalCost)
                                .reduce((prev, next) => prev + next)
                                .toFixed(2)}
                              €
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Filter
              hideSearch
              onFilter={(ev) => this.onFilter(ev)}
              orderItemStatusList={[
                OrderItemStatus.PreCompleted,
                OrderItemStatus.Completed,
                OrderItemStatus.OutOfStock,
                OrderItemStatus.Error,
              ]}
            />
            <div className="col-12 col-sm-12 card p-5">
              {this.state.tableIsLoading ? (
                <Loader />
              ) : (
                <div>
                  <div className="table-total-count">
                    Total Count: {orderItems[0]?.TotalCount}
                  </div>
                  <div className="table-responsive-xl">
                    <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
                      <thead>
                        <tr>
                          <th>
                            <p>Customer IP</p>
                          </th>
                          <th>
                            <p>Customer Country</p>
                          </th>
                          <th>
                            <p>
                              Package Date<sup>UTC</sup>
                            </p>
                          </th>
                          <th>
                            <p>
                              Completed Date<sup>UTC</sup>
                            </p>
                          </th>
                          <th>
                            <p>Item Status</p>
                          </th>

                          <th className="p-0 text-center">
                            {(this.state.statusList === "OutOfStock" ||
                              this.state.statusList === "PreCompleted") &&
                              orderItems[0]?.Items.length !== 0 && (
                                <div className="dropdown">
                                  <button
                                    className="btn order-retry-all-button dropdown-toggle"
                                    style={{ height: "auto" }}
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      width={30}
                                      height={30}
                                      src={Icons.ellipsisMenuIcon}
                                    />
                                    Actions
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => this.retryAllOrderItem()}
                                      >
                                        Retry All
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          this.refundBackOrderedItems()
                                        }
                                      >
                                        Refund All
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            {this.state.filter?.Status?.length === 1 &&
                              this.state.filter?.Status.includes("Error") &&
                              orderItems[0]?.Items.length !== 0 && (
                                <Button
                                  className="order-retry-all-button"
                                  label="Delete Items"
                                  bindEvent={() => this.deleteErrorOrderItem()}
                                  buttonIcon={Icons.removeIcon}
                                  iconWidth={18}
                                  iconHeight={18}
                                />
                              )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderItems[0]?.Items.length === 0 ? (
                          <label className="order-detail-table-no-item">
                            There is no available key !
                          </label>
                        ) : (
                          orderItems[0]?.Items.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <p>{item.CustomerIp ?? "-"}</p>
                                </td>
                                <td>
                                  <p>{item.CustomerCountryCode ?? "-"}</p>
                                </td>
                                <td>
                                  <p>{item.PackedOnUtc ?? "-"}</p>
                                </td>
                                <td>
                                  <p>
                                    {moment
                                      .utc(item.CompletedOnUtc)
                                      .format("DD.MM.YYYY - HH:mm:ss") ?? "-"}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className={`${
                                      item.Status === "Completed"
                                        ? "order-detail-completed-message"
                                        : item.Status === "OutOfStock"
                                        ? "order-detail-out-of-stock-message"
                                        : item.Status === "Error"
                                        ? "order-detail-error-message"
                                        : "order-detail-precompleted-message"
                                    }`}
                                  >
                                    {item.Status ?? "-"}
                                  </p>
                                </td>
                                <td className="p-0 text-center  d-flex justify-content-center">
                                  <p
                                    className={`${
                                      item.Status === "Completed"
                                        ? "order-detail-completed-message"
                                        : "text-primary"
                                    }`}
                                  >
                                    <Button
                                      className="order-detail-view-detail-button"
                                      label=""
                                      bindEvent={() =>
                                        this.detailModalOpen(
                                          item.Id,
                                          item.Status,
                                          item.StatusNotes
                                        )
                                      }
                                    >
                                      <img src={Icons.viewDetailIcon} />
                                    </Button>
                                    {item.Status === "Error" ? (
                                      <Button
                                        className="order-detail-remove-button"
                                        label=""
                                        bindEvent={() =>
                                          this.deleteOrderItem(item.Id)
                                        }
                                        confirmable
                                      >
                                        <img src={Icons.removeIcon} />
                                      </Button>
                                    ) : item.Status === "OutOfStock" ||
                                      item.Status === "PreCompleted" ? (
                                      <Button
                                        className="order-detail-retry-button"
                                        label=""
                                        bindEvent={() =>
                                          this.retryOrderItem(item.Id)
                                        }
                                        confirmable
                                      >
                                        <img src={Icons.retryIcon} />
                                      </Button>
                                    ) : null}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <Pager
              pageIndex={orderItems[0]?.PageIndex}
              totalPages={orderItems[0]?.TotalPages}
              totalCount={orderItems[0]?.TotalCount}
              onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
            />
            <MerModal
              id="order-confirm"
              showModal={this.state.showDetail}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.detailModalClose()}
            >
              <ManagementOrderItemDetail
                orderItemId={this.state.orderItemId}
                orderItemStatus={this.state.orderItemStatus}
                orderItemMessage={this.state.orderItemMessage}
                handleClose={() => this.detailModalClose()}
              ></ManagementOrderItemDetail>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
